import React, { memo, useEffect, useState } from 'react'
import { Button, TextInput } from '../../common'
import BgBestProperties from '../../../assets/bg_best_properties_dark.webp'
import {
  FULL_TEXT,
} from '../../../constants/storageNames'
import { Cookies } from 'react-cookie'
import { isMobile, isValidList } from '../../../common_functions'
import SeparatorMicrophoneGray from '../../../assets/icons/separator-microphone-gray.svg'
import Voice2Text from '../../Voice2Text'


function SectionBestPropertiesFullTextBasic() {
  const [valueInput, setValueInput] = useState('')
  const [countIndexSuggestion, setCountIndexSuggestion] = useState(0)
  const [placeholder, setPlaceholder] = useState('')
  const [heightSection, setHeightSection] = useState('654px')

  const cookies = new Cookies()
  const [originalList, setOriginalList] = useState<string[]>([])

  useEffect(() => {
      let header = document.querySelector('header') as HTMLElement
      let section = document.querySelector('.section-best-properties') as HTMLElement
      if (section && header && isMobile()){
        if(window.innerHeight - header.clientHeight >= 653)
        setHeightSection(window.innerHeight - 10 + 'px')
      }
  }, [])

  const handleChangeInput = (val: string) => {
    setValueInput(val)
    sessionStorage.setItem(FULL_TEXT, val)
  }

  return (
    <section className="section-best-properties md:aspect-auto flex">
      <div
        style={{ backgroundImage: `url(${BgBestProperties})`, height: heightSection}}
        className="w-full h-auto pt-[15rem] lg:pt-0 content-end md:content-normal md:min-h-[654px] bg-cover bg-no-repeat bg-center rounded-lg md:rounded-none"
      >
        <div
          id="containerSearchProperty"
          data-padding={40}
          className={
            'container-search-property max-w-[1000px] rounded-lg mx-5 md:w-4/5 m-auto text-center py-6 lg:ps-[10rem] relative md:-bottom-[16rem] left-0 right-0'
          }
        >
          <h2 className="text-2xl lg:text-5xl lg:text-start text-[#FFFFFF] mt-5 lg:w-[75%]">
            Encontre seu imóvel novo por texto ou voz no{' '}
            <span className="text-[#FF9A00] font-bold">glemO</span>
          </h2>
          <div className="w-full mt-8">
            <div className="grid lg:grid-cols-7 gap-3 lg:gap-2">
              <div className="lg:col-span-5">
                <div className="flex px-2 rounded text-md border-1 border-gray-200 outline-none placeholder:text-gray-600 max-w-100 bg-white">
                  <TextInput.Root className="border-none bg-white">
                    <TextInput.Input
                      onChange={(e) => handleChangeInput(e.target.value)}
                      value={valueInput}
                      className={`w-full bg-transparent text-xl input-search-best-properties-full-text`}
                      placeholder={"Área privativa espaçosa para churrasco"}
                    />
                  </TextInput.Root>
                  <div className="text-center m-auto pe-2">
                    <img src={SeparatorMicrophoneGray} alt="Separador" />
                  </div>
                  <div className="text-center m-auto cursor-pointer">
                    {/* <img src={MicrophoneIcon} /> */}
                    <Voice2Text
                    />
                  </div>
                </div>
              </div>
              <div className="lg:col-span-2 lg:h-full text-center max-w-100">
                <Button
                  id="searchBestProperties"
                  variant="primary"
                  className="font-bold w-full text-xl lg:h-full lg:w-[200px] max-w-100"
                >
                  BUSCAR
                  <span className='ps-2'>
                    <svg
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_8760_453)">
                        <path
                          d="M13.9999 13.6755L10.347 10.0227C11.2959 8.86221 11.7624 7.38141 11.6501 5.8866C11.5378 4.3918 10.8552 2.99734 9.74363 1.99166C8.63201 0.985992 7.17638 0.446048 5.67783 0.483517C4.17927 0.520985 2.75245 1.133 1.69248 2.19297C0.632509 3.25294 0.0204952 4.67976 -0.0169734 6.17832C-0.0544419 7.67687 0.485502 9.1325 1.49117 10.2441C2.49685 11.3557 3.89131 12.0383 5.38611 12.1506C6.88092 12.2629 8.36172 11.7964 9.5222 10.8475L13.175 14.5004L13.9999 13.6755ZM5.8332 11.0004C4.91022 11.0004 4.00797 10.7267 3.24054 10.2139C2.47311 9.70111 1.87498 8.97227 1.52177 8.11955C1.16856 7.26683 1.07614 6.32852 1.25621 5.42327C1.43627 4.51803 1.88073 3.68651 2.53337 3.03386C3.18602 2.38122 4.01754 1.93676 4.92278 1.7567C5.82803 1.57663 6.76634 1.66905 7.61906 2.02226C8.47178 2.37547 9.20062 2.9736 9.7134 3.74103C10.2262 4.50846 10.4999 5.41071 10.4999 6.33369C10.4985 7.57094 10.0064 8.75712 9.1315 9.63199C8.25663 10.5069 7.07045 10.999 5.8332 11.0004Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_8760_453">
                          <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default memo(SectionBestPropertiesFullTextBasic)
