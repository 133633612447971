import React from 'react'
import Arrow from '../../assets/icons/icon-arrow-up.svg'
import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { EnterpriseTechnicalInformation } from '../../interfaces/enterprise/enterpriseTechnicalInformation'
import { isValidList, isValidStr } from '../../common_functions'
import { UnitType } from '../../interfaces/enterprise/enterpriseUnitType'
import { IconTitle } from '../../interfaces/enterprise/enterpriseIconTitle'
import { IconName } from '../../interfaces/enterprise/enterpriseIconName'
import enterpriseApi from '../../store/reducers/enterprise/enterpriseApi'
import SkeletonLoading from '../common/SkeletonLoading'

interface EnterpriseDetailCard {
  Title: string | undefined
  EnterpriseId: string
  Realization: string
}

export default function FichaTecnica({ Title, EnterpriseId, Realization }: EnterpriseDetailCard) {
  const {
    data: dataAddress,
    isSuccess: isSuccessAddress,
    isError: isErrorAddress,
    isLoading: isLoadingAddress
  } = enterpriseApi.useGetEnterpriseAddressByIdQuery(EnterpriseId ?? '')
  const {
    data: dataInfo,
    isSuccess: isSuccessInfo,
    isError: isErrorInfo,
    isLoading: isLoadingInfo
  } = enterpriseApi.useGetEnterpriseInformationByIdQuery(EnterpriseId ?? '')
  const {
    data: dataDifferentials,
    isSuccess: isSuccessDifferentials,
    isError: isErrorDifferentials,
    isLoading: isLoadingDifferentials
  } = enterpriseApi.useGetEnterpriseDifferentialsByIdQuery(EnterpriseId ?? '')
  const {
    data: dataProximities,
    isSuccess: isSuccessProximities,
    isError: isErrorProximities,
    isLoading: isLoadingProximities
  } = enterpriseApi.useGetEnterpriseProximitiesByIdQuery(EnterpriseId ?? '')
  const {
    data: dataAdjacentsDistricts,
    isSuccess: isSuccessAdjacentsDistricts,
    isError: isErrorAdjacentsDistricts,
    isLoading: isLoadingAdjacentsDistricts
  } = enterpriseApi.useGetEnterpriseAdjacentsDistrictsByIdQuery(EnterpriseId ?? '')

  const [hiddenContent, setHiddenContent] = useState(true)
  const [dataIconsDifferentials, setDataIconsDifferentials] = useState<IconTitle[]>()
  const [dataIconsProximities, setDataIconsProximities] = useState<IconTitle[]>()

  useEffect(() => {
    if (isSuccessDifferentials && dataDifferentials?.Data.length > 0) {
      var listIcons: IconTitle[] = []
      dataDifferentials?.Data.forEach((element) => {
        listIcons.push({ Icon: element.Differential.Icon, Title: element.Differential.Title ?? '' })
      })
      setDataIconsDifferentials(listIcons)
    }
  }, [isSuccessDifferentials])

  useEffect(() => {
    if (isSuccessProximities && dataProximities?.Data.length > 0) {
      var listIcons: IconTitle[] = []
      dataProximities?.Data.forEach((element) => {
        listIcons.push({ Icon: element.Proximity.Icon, Title: element.Proximity.Title ?? '' })
      })
      setDataIconsDifferentials(listIcons)
    }
  }, [isSuccessProximities])

  const getTotalArea = () => {
    if (isSuccessInfo) {
      if (parseInt(dataInfo?.Data.TotalArea)) {
        return `${parseInt(dataInfo?.Data.TotalArea)}m²`
      } else {
        return dataInfo?.Data.TotalArea
      }
    }
    return ''
  }

  return (
    <>
      <section className="p-3 pl-0" id="navInternEnterpriseTechnicalInfos">
        <h3 className="font-bold text-xl md:text-2xl my-5 text-[#4D4D4D]">
          Ficha Técnica
        </h3>
        <div className={clsx()}>
          <p className="text-xl text-black opacity-80 font-bold">
            {isValidStr(Title) ? <>Produto: <span className='font-[500]'>{Title}</span></> : <></>}
            {isLoadingAddress && <SkeletonLoading height={100} />}
            {isSuccessAddress && (
              <>
                <br />
                Endereço: <span className='font-[500]'>{dataAddress?.Data.Location}</span>
              </>
            )}
            {isLoadingInfo && <SkeletonLoading height={100} />}
            {isSuccessInfo ? (
              <>
                <br />
                Área Total do Terreno: <span className='font-[500]'>{getTotalArea()}</span>
              </>
            ) : (
              <></>
            )}
            {isLoadingDifferentials && <SkeletonLoading height={100} />}
            {isSuccessDifferentials && dataDifferentials.Data.length > 0 ? (
              <>
                <br />
                Diferenciais: <br />
                <span className="font-[500]">
                  {dataDifferentials?.Data?.map((item, i) => {
                    return `${item.Differential.Title}${
                      i < dataDifferentials?.Data.length - 1 ? ', ' : ''
                    }`
                  })}
                </span>
              </>
            ) : (
              <></>
            )}
            {/* {isValidList<string>(RecreationAreas) ? (
            <>
              <br />
              Área de lazer:{' '}
              {RecreationAreas?.map((item, i) => {
                return `${item}${i < RecreationAreas.length - 1 ? ', ' : ''}`
              })}
            </>
          ) : (
            <></>
          )} */}
            {/* {isValidList<UnitType>(UnitTypes) ? (
              <>
                <br />
                Tipologia das unidades:{' '}
                {UnitTypes?.map((item, i) => {
                  return `${item.Value}${i < UnitTypes.length - 1 ? ', ' : ''}`
                })}
              </>
            ) : (
              <></>
            )}
            {isValidStr(RoomsFullText) ? (
              <>
                <br />
                {RoomsFullText}
                <br />
                *Área real privativa acessória com valores aproximados.
              </>
            ) : (
              <></>
            )} */}
            {/* {isValidStr(SuitesFullText) ? (
            <>
              <br />
              {SuitesFullText}
              <br />
              *Área real privativa acessória com valores aproximados.
            </>
          ) : (
            <></>
          )} */}
            {isSuccessProximities && dataProximities.Data.length > 0 ? (
              <>
                <br />
                Proximidade: <br />
                <span className="font-[500]">
                  {dataProximities?.Data?.map((item, i) => {
                    return `${item.Proximity.Name}${
                      i < dataProximities?.Data.length - 1 ? ', ' : ''
                    }`
                  })}
                </span>
              </>
            ) : (
              <></>
            )}
            {isSuccessAdjacentsDistricts && dataAdjacentsDistricts?.Data.length > 0 ? (
              <>
                <br />
                Vias de acesso:{' '}
                <span className="font-[500]">
                  {dataAdjacentsDistricts?.Data?.map((item, i) => {
                    return `${item.District.Name}${
                      i < dataAdjacentsDistricts?.Data.length - 1 ? ', ' : ''
                    }`
                  })}
                </span>
              </>
            ) : (
              <></>
            )}
            {isValidStr(Realization) ? (
              <>
                <br />
                Realização:
                <br />
                <span className="font-[500]">{Realization}</span>
              </>
            ) : (
              <></>
            )}
            {isSuccessInfo ? (
              <>
                <br />
                Registro de Incorporação:
                <br />
                <span className="font-[500]">{dataInfo.Data.IncorporationRegistration}</span>
              </>
            ) : (
              <></>
            )}
          </p>
        </div>
      </section>
      <hr className=" z-[-1] relative opacity-10 mt-4" />
    </>
  )
}
