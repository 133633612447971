import React from 'react'
import {  useState } from 'react'
import clsx from 'clsx'
import { strToSlug } from '../../common_functions'
import SkeletonLoading from '../common/SkeletonLoading'
import IconLoading from '../../assets/loading-gif.gif'
import enterpriseApi from '../../store/reducers/enterprise/enterpriseApi'

interface EnterpriseDetailCard {
  EnterpriseId: string
}

export default function Downloads({ EnterpriseId }: EnterpriseDetailCard) {
  const {data, isSuccess, isError, isLoading} = enterpriseApi.useGetEnterprisePresentationFilesByIdQuery(EnterpriseId ?? "")
  const [hiddenContent, setHiddenContent] = useState(true)
  const [showErrorDownload, setShowErrorDownload] = useState(false)
  const idInitAlert = 'alertErrorDownload'
  const idInitLoading = 'loadingDownload'

  const handleDownload = async (url: string, filename: string, slugEl: string) => {
    const idAlertHTML = idInitAlert + slugEl
    const idLoadingHTML = idInitLoading + slugEl
    handleShowLoadingDownload(idLoadingHTML)
    try {
      const response = await fetch(url)
      if (response.status === 200) {
        const blob = await response.blob()
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = downloadUrl
        link.download = filename
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(downloadUrl)
      } else {
        // Handle the error if the response is not successful
        handleShowLoadingDownload(idLoadingHTML)
        handleShowErrorDownload(idAlertHTML)
      }
    } catch (error) {
      // Handle the error if the fetch fails
      console.error(error)
      handleShowLoadingDownload(idLoadingHTML)
      handleShowErrorDownload(idAlertHTML)
    }

    setTimeout(() => {
      handleShowErrorDownload(idAlertHTML)
    }, 2000)
  }

  const handleShowErrorDownload = (idHTML: string) => {
    let alert = document.querySelector('#' + idHTML) as HTMLElement
    if (alert != undefined) {
      alert.classList.toggle('hidden')
    }
  }

  const handleShowLoadingDownload = (idHTML: string) => {
    let alert = document.querySelector('#' + idHTML) as HTMLElement
    if (alert != undefined) {
      alert.classList.toggle('hidden')
      alert.classList.toggle('inline-block')
    }
  }

  return (
    <>
      {isLoading && <SkeletonLoading height={150}/> }
      {isSuccess && data?.Data.length > 0 ? (
        <>
          <section className="p-3 pl-0">
            <div id='navInternEnterpriseDownloads'>

              <h3 className="font-bold text-xl md:text-2xl my-5 text-[#4D4D4D]">
                Downloads
              </h3>
            </div>
            <div className={clsx('text-center')}>
              {data?.Data?.map((item) => {
                return (
                  <div key={`${strToSlug(item.Id)}`}>
                    <a target="_blank" href={`${item.Url}`}>
                      <button
                        // onClick={() =>
                        //   handleDownload(
                        //     item.Url
                        //     item.Title,
                        //     strToSlug(item.Title)
                        //   )
                        // }
                        id={item.Title != undefined ? strToSlug(item.Title) : ''}
                        className="bg-[#C4C4C4]/[0.3] mx-auto w-full md:w-auto rounded-lg px-3 py-1 my-4"
                      >
                        {/* DOWNLOAD ICON */}
                        <svg
                          className="inline-block"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 9.5H15V3.5H9V9.5H5L12 16.5L19 9.5ZM11 11.5V5.5H13V11.5H14.17L12 13.67L9.83 11.5H11ZM5 18.5H19V20.5H5V18.5Z"
                            fill="#0059FF"
                          />
                        </svg>
                        <span className="text-sm text-[#4D4D4D]">
                          {item.Title}{' '}
                          <img
                            src={IconLoading}
                            id={`${idInitLoading}${strToSlug(item.Title)}`}
                            className="hidden ms-2 w-[15px]"
                          />
                        </span>
                      </button>
                    </a>
                    <div
                      id={`${idInitAlert}${strToSlug(item.Title)}`}
                      className="bg-red-100 max-w-full w-fit hidden pe-10 m-auto border border-red-400 text-red-700 px-4 py-3 rounded relative"
                      role="alert"
                    >
                      <strong className="font-bold">Houve um erro!</strong>
                      <span className="block sm:inline">
                        {' '}
                        Seu download não foi feito porque houve um erro durante o processo, tente
                        novamente mais tarde.
                      </span>
                      <span
                        className="absolute top-0 bottom-0 right-0 px-4 py-3 cursor-pointer"
                        onClick={() =>
                          handleShowErrorDownload(`alertErrorDownload${strToSlug(item.Title)}`)
                        }
                      >
                        <svg
                          className="fill-current h-6 w-6 text-red-500"
                          role="button"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <title>Close</title>
                          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                        </svg>
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
          </section>
          <hr className=" z-[-1] relative opacity-10" />
        </>
      ) : (
        <></>
      )}
    </>
  )
}
