import { Suspense, useEffect, useState } from 'react'
import { EnterprisePhotos } from '../../../interfaces/enterprise/enterprisePhotos'
import { isValidStr } from '../../../common_functions'
import SkeletonLoading from '../../common/SkeletonLoading'
import FavoriteEnterprise from '../../FavoriteEnterprise'
import { useSwipeable } from 'react-swipeable'

interface Props {
  showModal: boolean
  closeModal: () => void
  photos: EnterprisePhotos[]
}

export function ModalCarouselEnterpriseMobile({ showModal, closeModal, photos }: Props) {
  const [showPopup, setShowPopup] = useState(false)
  const [currentBannerIndex, setCurrentBannerIndex] = useState<number>(0)

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextBannerCarousel(),

    onSwipedRight: () => handlePrevBannerCarousel()
  })

  const handleNextBannerCarousel = () => {
    if (currentBannerIndex < (photos.length ?? 0) - 1)
      setCurrentBannerIndex(currentBannerIndex + 1)
  }

  const handlePrevBannerCarousel = () => {
    if (currentBannerIndex != 0) setCurrentBannerIndex(currentBannerIndex - 1)
  }

  useEffect(() => {

  }, [currentBannerIndex])

  const changeCurrentBannerIndex = (index: number) => {
    if(index != currentBannerIndex) changeOverflowCarouselGallery(index < currentBannerIndex)
    setCurrentBannerIndex(index)
  }

  const changeOverflowCarouselGallery = (left: boolean) => {
    const carousel = document.querySelector(".carousel-gallery-modal-enterprise") as HTMLElement
    if(carousel){
      const currentScroll = carousel.scrollLeft
      carousel.scroll({left: left ? currentScroll - 100 : currentScroll + 100})
    }

    setTimeout(() => {
        carousel.style.scrollBehavior = 'smooth';
    }, 50);
  }

  return (
    <>
      {showModal && (
        <>
          <div className="modal-form-contact justify-center items-center md:flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-[1000] outline-none focus:outline-none md:rounded-lg m-auto">
            <div className="relative w-auto mx-auto h-[100%] lg:h-auto  max-w-[100%]">
              <div className="container-modal pt-6 border-0 md:rounded-lg h-[100%] md:h-auto shadow-xl relative flex flex-col outline-none focus:outline-none p-4 overflow-y-auto w-full md:w-[553px] max-w-[100%] md:max-h-screen">
                {/* CONTENT */}
                <div className="" style={{ alignSelf: 'end' }}>
                  <div className="bg-white rounded-sm w-[26px] aspect-square text-center flex align-middle">
                    <span
                      className="bg-transparent inline-block m-auto outline-none focus:outline-none"
                      onClick={closeModal}
                    >
                      {/* <X color="black" size={20} weight="bold" /> */}
                      <svg
                        width={12}
                        fill="currentColor"
                        className="block m-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 256 256"
                      >
                        <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
                      </svg>
                    </span>
                  </div>
                </div>
                <div>
                  {/* mainbanner */}
                  <div>
                    {!isValidStr(photos[currentBannerIndex].FileUrl) ? (
                      <SkeletonLoading className="aspect-[4/2]" />
                    ) : (
                      <div className="main-photo-modal-carousel-enterprise px-3 absolute top-[6rem]" {...handlers}>
                        <img
                          className="w-full rounded-md max-h-[500px]"
                          src={photos[currentBannerIndex].FileUrl ?? ''}
                        />
                        <div className="text-white text-center absolute z-[1001] position-area-center -mb-10 bg-[#0A0A0ACC] w-fit m-auto px-2">
                          <span className="m-auto inline-block">
                            <svg
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              className="inline-block -mt-2"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.5 12.6667C10.8117 12.6667 11.875 11.6035 11.875 10.2917C11.875 8.98004 10.8117 7.91675 9.5 7.91675C8.18829 7.91675 7.125 8.98004 7.125 10.2917C7.125 11.6035 8.18829 12.6667 9.5 12.6667Z"
                                stroke="white"
                                stroke-width="1.58333"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M2.375 13.3001V7.28341C2.375 6.39667 2.375 5.95329 2.54758 5.6146C2.69937 5.31667 2.94159 5.07445 3.23952 4.92266C3.57821 4.75008 4.02159 4.75008 4.90833 4.75008H5.74326C5.84058 4.75008 5.88925 4.75008 5.93414 4.74494C6.1684 4.71811 6.3785 4.58826 6.50725 4.39074C6.53193 4.35288 6.5537 4.30934 6.59722 4.2223C6.68427 4.04821 6.7278 3.96115 6.77716 3.88544C7.03467 3.4904 7.45487 3.2307 7.9234 3.17703C8.01317 3.16675 8.11047 3.16675 8.30514 3.16675H10.6949C10.8895 3.16675 10.9868 3.16675 11.0766 3.17703C11.5451 3.2307 11.9653 3.4904 12.2229 3.88544C12.2722 3.96115 12.3157 4.04823 12.4028 4.2223C12.4463 4.30935 12.468 4.35288 12.4927 4.39074C12.6215 4.58826 12.8316 4.71811 13.0658 4.74494C13.1108 4.75008 13.1594 4.75008 13.2568 4.75008H14.0917C14.9784 4.75008 15.4218 4.75008 15.7605 4.92266C16.0584 5.07445 16.3007 5.31667 16.4524 5.6146C16.625 5.95329 16.625 6.39667 16.625 7.28341V13.3001C16.625 14.1868 16.625 14.6302 16.4524 14.9689C16.3007 15.2668 16.0584 15.5091 15.7605 15.6608C15.4218 15.8334 14.9784 15.8334 14.0917 15.8334H4.90833C4.02159 15.8334 3.57821 15.8334 3.23952 15.6608C2.94159 15.5091 2.69937 15.2668 2.54758 14.9689C2.375 14.6302 2.375 14.1868 2.375 13.3001Z"
                                stroke="white"
                                stroke-width="1.58333"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                          <span className="ps-3 text-2xl">
                            {currentBannerIndex + 1}/{photos?.length}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="fixed bottom-0 left-0 w-full">
                    <div
                      className="overflow-x-scroll carousel-gallery-modal-enterprise flex gap-2 px-2 pb-5"
                      style={{ whiteSpace: 'nowrap', transition: "scroll 0.2s ease"  }}
                    >
                      {photos.map((item, index) => {
                        return (
                          <>
                            {item && (
                              <button
                                className="btn w-fit"
                                type="button"
                                onClick={() =>
                                  changeCurrentBannerIndex(index)
                                }
                              >
                                <div
                                  className="aspect-[100/64] w-[100px]"
                                  style={{
                                    backgroundImage: `url('${item?.FileUrl ?? ""}')`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                  }}
                                ></div>
                              </button>
                            )}
                          </>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-90 fixed inset-0 z-[100] bg-black"></div>
        </>
      )}
    </>
  )
}
