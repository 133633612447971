import React from 'react'
import { Button } from '../../common'
import { useEffect, useState } from 'react'
interface Props {
  showModal: boolean
  closeModal: () => void
  status?: 'error' | 'loading' | 'success'
  imgUrl: string
}

export function ModalPresentation({ showModal, closeModal, status = 'loading', imgUrl }: Props) {
  const [colorStatus, setColorStatus] = useState('')
  const [bgStatus, setBgStatus] = useState('')
  const [titleStatus, setTitleStatus] = useState('')
  const [descriptionStatus, setDescriptionStatus] = useState('')

  useEffect(() => {
    switch (status) {
      case 'error':
        setTitleStatus('Houve um erro!')
        setDescriptionStatus(
          'Houve um erro durante a sua solicitação! Por favor, tente novamente mais tarde ou entre em contato com o suporte.'
        )
        setColorStatus('#df0101')
        setBgStatus('#F8E0E0')
        break
      case 'success':
        setTitleStatus('Sucesso!')
        setDescriptionStatus('Parabéns! Sua solicitação foi realizada com sucesso!')
        setBgStatus('#D1FAE5')
        setColorStatus('#059669')
      default:
        setTitleStatus('Solicitação em carregamento...')
        setDescriptionStatus('Sua solicitação está em processamento.')
        setBgStatus('#F5F6CE')
        setColorStatus('#FFBF00')
        break
    }
  }, [status])
  return (
    <>
      {showModal ? (
        <>
          <div className="modal-form-journey bg-black-60 justify-center items-center flex overflow-x-hidden fixed inset-0 z-[1000] outline-none focus:outline-none m-auto">
            <div className="relative mx-auto">
              <div className="container-modal rounded-lg shadow-xl relative bg-white mt-20 outline-none focus:outline-none w-[95%] lg:w-[80%] m-auto py-5 ">
                <div className="modal-header px-10 bg-no-repeat bg-cover bg-center">
                  <button
                    type="button"
                    onClick={closeModal}
                    style={{ float: 'inline-end' }}
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  >
                    <span className="sr-only">Close menu</span>
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="modal-body px-4 lg:px-10 text-center">
                  <img src={imgUrl} className='w-full'/>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-[2] bg-black"></div>
        </>
      ) : null}
    </>
  )
}
