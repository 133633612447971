import React from 'react'
import { Button } from '../../common'
import { useEffect, useState } from 'react'
interface Props {
  showModal: boolean
  closeModal: () => void
  title?: string
  description?: string
}

export function ModalError({ showModal, closeModal, title, description }: Props) {
  const [colorStatus, setColorStatus ] = useState('')
  const [bgStatus, setBgStatus] = useState('')
  const [titleStatus, setTitleStatus] = useState(title)
  const [descriptionStatus, setDescriptionStatus] = useState(description)

  useEffect(() => {
    switch (status) {
      case 'error':
        setColorStatus('#df0101')
        setBgStatus('#F8E0E0')
        break;
    }
  }, [])
  return (
    <>
      {showModal ? (
        <>
          <div className="modal-form-journey justify-center items-center flex overflow-x-hidden fixed inset-0 z-[100000] outline-none focus:outline-none rounded-lg m-auto">
            <div className="relative w-auto mx-auto  max-w-full">
              <div className="container-modal rounded-lg shadow-xl relative bg-white min-h-[336px] mt-20 outline-none focus:outline-none md:w-[553px] max-w-full mx-5 py-4 max-h-screen">
                <div className="modal-header px-10 py-5 mt-4 bg-no-repeat bg-cover bg-center">
                  <svg
                    width="48"
                    className="text-center m-auto"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="48" height="48" rx="24" fill={bgStatus} />
                    <path
                      d="M17 25L21 29L31 19"
                      stroke={colorStatus}
                      strokeWidth="2"
                      strokeLinecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="modal-body px-4 lg:px-10 text-center">
                  <h2 className="text-2xl text-black font-bold ">{titleStatus}</h2>
                  <p className='text-lg mt-4'>
                    {descriptionStatus}
                  </p>
                <Button variant='orange' className='mt-5 m-auto w-full' disabled={status == 'loading'} onClick={closeModal}>OK, OBRIGADO!</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-[2] bg-black"></div>
        </>
      ) : null}
    </>
  )
}
