import IconCar from '../../assets/icons/icon-car.svg'
import React, { lazy, useEffect } from 'react'
import IconBedroom from '../../assets/icons/icon-bedroom.svg'
import IconRuler from '../../assets/icons/icon-ruler.svg'
// import { MapPin } from 'phosphor-react'
import { Link } from 'react-router-dom'
import SkeletonLoading from '../common/SkeletonLoading'
import { Suspense, useState } from 'react'
import enterpriseApi from '../../store/reducers/enterprise/enterpriseApi'
import {
  BEDROOM,
  DORMITORY,
  PARKING_SPACES,
  ROOMS
} from '../../constants/enterpriseSpecificationsTypes'
import statusApi from '../../store/reducers/status/statusApi'
import { CardFormContactLead } from '../Cards/CardFormContactLead'
import { v4 } from 'uuid'

interface EnterpriseDetailCard {
  Id: string | undefined
  ConstructionCompanyId: string | undefined
  Description: string | undefined
  StatusId: string | undefined
}

export default function BaseInfos({
  Id,
  Description,
  ConstructionCompanyId,
  StatusId
}: EnterpriseDetailCard) {
  const [openShareModal, setOpenShareModal] = useState(false)
  const [parkingSpaces, setParkingSpaces] = useState(0)
  const [rooms, setRooms] = useState<number[]>([])

  const {
    data: dataTipologies,
    isError: isErrorTipologies,
    isLoading: isLoadingTipologies,
    isSuccess: isSuccessTipologies,
    status: statusTipologies,
    error: errorTipologies
  } = enterpriseApi.useGetEnterpriseTipologiesByIdQuery(Id ?? '')

  const {
    data: dataInfo,
    isError: isErrorInfo,
    isLoading: isLoadingInfo,
    isSuccess: isSuccessInfo,
    status: statusInfo,
    error: errorInfo
  } = enterpriseApi.useGetEnterpriseInformationByIdQuery(Id ?? '')

  const handleShareModal = () => {
    setOpenShareModal(!openShareModal)
  }

  useEffect(() => {
    if (isSuccessTipologies) getTipologies()
  }, [isSuccessTipologies])

  const getTipologies = () => {
    var list: number[] = []
    if (dataTipologies != undefined && dataTipologies.Data.length > 0) {
      dataTipologies?.Data.forEach((element) => {
        if (element.Specification.Type === PARKING_SPACES) {
          setParkingSpaces(element.Specification.Quantity)
        }
        if (
          element.Specification.Type === DORMITORY ||
          element.Specification.Type === ROOMS ||
          element.Specification.Type === BEDROOM
        ) {
          list.push(element.Specification.Quantity)
        }
      })
    }

    list = [...new Set(list)]
    list.sort()
    setRooms(list)
  }

  const getTotalArea = () => {
    if (isSuccessInfo) {
      if (parseInt(dataInfo?.Data.TotalArea)) {
        return `${parseInt(dataInfo?.Data.TotalArea)}m²`
      } else {
        return dataInfo?.Data.TotalArea
      }
    }
    return ''
  }

  return (
    <>
      <div className="group-infos-characteristics grid grid-cols-3 -mt-5 lg:mt-auto gap-10 lg:w-fit grid-flow-col">
        <div className="m-auto">
          <div className="gap-3 text-center lg:text-start">
            <div className="text-center">
              <Suspense fallback={<SkeletonLoading />}>
                <img
                  alt="Bedroom Icon"
                  src={IconBedroom}
                  className="w-[24px] inline"
                  style={{ aspectRatio: '1/1' }}
                />
              </Suspense>
            </div>
            <div className="col-span-3">
              <p className="text-gray-600 font-semibold text-lg">
                {rooms.length > 1 ? rooms[0] + ' a ' + rooms[rooms.length - 1] : rooms[0]} quarto(s)
              </p>
            </div>
          </div>
        </div>
        <div className="m-auto">
          <div className="gap-3 text-center lg:text-start">
            <div className="text-center">
              <Suspense fallback={<SkeletonLoading />}>
                <img
                  alt="Car Icon"
                  src={IconCar}
                  className="w-[24px] inline"
                  style={{ aspectRatio: '1/1' }}
                />
              </Suspense>
            </div>
            <div className="col-span-3">
              <p className="text-gray-600 font-semibold text-lg">
                {parkingSpaces} vaga {parkingSpaces <= 1 ? '' : '(s)'}
              </p>
            </div>
          </div>
        </div>
        <div className="m-auto">
          <div className=" gap-3 text-center lg:text-start">
            <div className="text-center">
              <Suspense fallback={<SkeletonLoading />}>
                <img
                  alt="Ruler Icon"
                  src={IconRuler}
                  className="w-[24px] inline"
                  style={{ aspectRatio: '1/1' }}
                />
              </Suspense>
            </div>
            {isErrorInfo && (
              <div className="alert alert-danger my-3" role="alert">
                Houve um erro ao carregar essa sessão do empreendimento, tente novamente mais tarde!
              </div>
            )}
            {isSuccessInfo && (
              <div className="col-span-3">
                <p className="text-gray-600 font-semibold text-lg">{getTotalArea()}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-5 z-[-1] relative text-lg lg:text-xl text-[#575757] font-semibold">
        <p>{Description}</p>
      </div>
    </>
  )
}
