import React from 'react'
import Icon from '../../assets/icons/icon-differentials-ex.webp'
import Arrow from '../../assets/icons/icon-arrow-up.svg'
import { Suspense, useEffect, useState } from 'react'
import clsx from 'clsx'
import SkeletonLoading from '../common/SkeletonLoading'
import { v4 } from 'uuid'
import { formatMoneyReal, isValidList, isValidStr, strToSlug } from '../../common_functions'
import { Enterprise2 } from '../../interfaces/enterprise/enterprise_2'
import { IEnterpriseUnit } from '../../interfaces/enterprise/units'
import IconShare from '../../assets/icons/icon-blue-share.svg'
import IconLoading from '../../assets/loading-gif.gif'
import IconArrow from '../../assets/icons/icon-white-arrow.svg'
import IconCar from '../../assets/icons/icon-car.svg'
import IconBedroom from '../../assets/icons/icon-bedroom.svg'
import IconRuler from '../../assets/icons/icon-ruler.svg'

import StaticImg from '../../assets/no-image.webp'
import enterpriseApi from '../../store/reducers/enterprise/enterpriseApi'
import { EnterpriseUnit } from '../../interfaces/enterprise/enterpriseUnit'
import { ENUM_PHOTO_PLANT } from '../../constants/enterprisePresentationMediaTypesEnums'
import {
  BEDROOM,
  DORMITORY,
  PARKING_SPACES,
  ROOMS
} from '../../constants/enterpriseSpecificationsTypes'
import { ModalPresentation } from '../Modais/ModalPresentation'

export interface Props {
  units: EnterpriseUnit
}

export default function KnowUnits({ units }: Props) {
  const [hiddenContent, setHiddenContent] = useState(true)
  const [indexSelectedButton, setIndexSelectedButton] = useState(0)
  const [selectedUnit, setSelectedUnit] = useState<EnterpriseUnit | undefined>()
  const [parkingSpaces, setParkingSpaces] = useState(0)
  const [rooms, setRooms] = useState<number[]>([])
  const [selectedImgModal, setSelectedImgModal] = useState('')
  const [showModal, setShowModal] = useState(false)

  const handleSelectedItem = (index: number) => {
    setIndexSelectedButton(index)
    setSelectedUnit(units)
  }

  const handleShowImg = (img: string) => {
    setSelectedImgModal(img)
    setShowModal(true)
  }

  useEffect(() => {
    if (units != undefined) {
      setSelectedUnit(units)
    }
  }, [units])

  useEffect(() => {
    getTipologies()
  }, [selectedUnit])

  const getTipologies = () => {
    var list: number[] = []
    if (units != undefined && units.Typology.length > 0) {
      units.Typology?.forEach((element) => {
        if (element.Specification.Type === PARKING_SPACES) {
          setParkingSpaces(element.Specification.Quantity)
        }
        if (
          element.Specification.Type === DORMITORY ||
          element.Specification.Type === ROOMS ||
          element.Specification.Type === BEDROOM
        ) {
          list.push(element.Specification.Quantity)
        }
      })
    }

    list = [...new Set(list)]
    list.sort()
    setRooms(list)
  }

  return (
    <>
      {units != undefined ? (
        <>
          <section className="p-3 pl-0">
            <div id={'navInternEnterpriseKnowUnits'}>
              <h3 className="font-bold text-xl md:text-2xl my-5 text-[#4D4D4D]">
                Conheça as Unidades
              </h3>
            </div>
            {/* CONTENT */}
            <div>
              <div className="grid lg:block grid-cols-2 gap-2">
                {/* {units?.map((item: IEnterpriseUnit, index) => {
                  return (
                    <button
                      onClick={() => handleSelectedItem(index)}
                      key={`${strToSlug(item.Title)}`}
                      className={`rounded-2xl px-4 py-2 lg:m-2 lg:min-w-[170px] inline-flex w-full lg:w-fit border-1 font-semibold border-[#CCC] ${
                        indexSelectedButton == index ? 'bg-[#F15C00] text-white' : 'text-gray-600'
                      }`}
                    >
                      <h5 className="text-xl m-auto">{item.Title}</h5>
                    </button>
                  )
                })} */}
                <button
                  onClick={() => handleSelectedItem(0)}
                  className={`rounded-2xl px-4 py-2 lg:m-2 lg:min-w-[170px] inline-flex w-full lg:w-fit border-1 font-semibold border-[#CCC] ${'bg-[#F15C00] text-white'}`}
                >
                  <h5 className="text-xl m-auto">Unidade 1</h5>
                </button>
              </div>
              {selectedUnit != undefined && (
                <section>
                  <div className="grid lg:grid-cols-3 mt-5">
                    <div>
                      <small className="text-gray-700 font-semibold text-lg">
                        {selectedUnit.PriceMinimumValue == 0 ? (
                          'Valor'
                        ) : (
                          <>A partir de:</>
                        )}
                      </small>
                      <h3 className="font-bold text-[#414141] text-4xl">
                        {selectedUnit.PriceMinimumValue == 0 ? (
                          'Sob Consulta'
                        ) : (
                          <>{formatMoneyReal(selectedUnit.PriceMinimumValue)}</>
                        )}
                      </h3>
                    </div>
                    <div className="my-5 lg:my-0">
                      <small className="text-gray-700 font-semibold text-lg">Tipo da Unidade</small>
                      <h5 className="text-base font-bold text-[#989A9A]">
                        {selectedUnit.UnitType.toUpperCase()}
                      </h5>
                    </div>
                    <div>
                      <small className="text-gray-700 font-semibold text-lg">
                        Status de Obra da Unidade
                      </small>
                      <h5 className="text-base font-bold text-[#989A9A]">
                        {selectedUnit.Status.toUpperCase()}
                      </h5>
                    </div>
                  </div>
                  <div className="group-infos-characteristics grid grid-cols-3 grid-flow-col py-4 w-fit gap-5 my-5">
                    <div className="m-auto">
                      <div className="grid grid-cols-4 grid-flow-col gap-3">
                        <div className="text-center">
                          <Suspense fallback={<SkeletonLoading />}>
                            <img alt="icon-bedroom" src={IconBedroom} className="w-1/7 inline" />
                          </Suspense>
                        </div>
                        <div className="col-span-3">
                          <p className="text-gray-600 font-semibold text-lg">
                            {rooms != undefined ? rooms + ' quarto(s)' : 'Não informado'}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="m-auto">
                      <div className="grid grid-cols-4 grid-flow-col gap-3">
                        <div className="text-center">
                          <Suspense fallback={<SkeletonLoading />}>
                            <img alt="icon-car" src={IconCar} className="w-1/7 inline" />
                          </Suspense>
                        </div>
                        <div className="col-span-3">
                          <p className="text-gray-600 font-semibold text-lg">
                            {parkingSpaces != undefined
                              ? parkingSpaces + ' vaga(s)'
                              : 'Não informado'}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="m-auto">
                      <div className="grid grid-cols-4 grid-flow-col gap-3">
                        <div className="text-center">
                          <Suspense fallback={<SkeletonLoading />}>
                            <img alt="icon-ruler" src={IconRuler} className="w-1/7 inline" />
                          </Suspense>
                        </div>
                        <div className="col-span-3">
                          <p className="text-gray-600 font-semibold text-lg">
                            {selectedUnit.TotalArea != undefined
                              ? selectedUnit.TotalArea + ' m²'
                              : 'Não informado'}
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {isValidList(selectedUnit.Media) &&
                    selectedUnit.Media.filter((x) => x.Category.Enumerator != ENUM_PHOTO_PLANT)
                      .length > 0 && (
                      <>
                        <div>
                          <h3 className="text-2xl text-[#4D4D4D] font-bold mb-5">
                            Fotos da unidade
                          </h3>
                          {/* <div className="grid lg:grid-cols-3 gap-2"> */}
                          <div className="columns-3 gap-2">
                            {selectedUnit.Media.filter(
                              (x) => x.Category.Enumerator != ENUM_PHOTO_PLANT
                            ).map((item) => {
                              return (
                                <img
                                  src={item.FileUrl}
                                  className="w-full pt-2 cursor-pointer"
                                  onClick={() => handleShowImg(item.FileUrl)}
                                />
                              )
                            })}
                          </div>
                        </div>
                      </>
                    )}
                  {isValidList(selectedUnit.Media) &&
                    selectedUnit.Media.filter((x) => x.Category.Enumerator == ENUM_PHOTO_PLANT)
                      .length > 0 && (
                      <>
                        <div>
                          <h3 className="text-2xl text-[#4D4D4D] font-bold my-5">
                            Planta da Unidade
                          </h3>
                          <div className="grid lg:grid-cols-3 gap-2">
                            {selectedUnit.Media.filter(
                              (x) => x.Category.Enumerator == ENUM_PHOTO_PLANT
                            ).map((item) => {
                              return (
                                <img
                                  src={item.FileUrl}
                                  className="w-full cursor-pointer"
                                  onClick={() => handleShowImg(item.FileUrl)}
                                />
                              )
                            })}
                          </div>
                        </div>
                      </>
                    )}
                  {isValidStr(selectedUnit.AdditionalInfo) && (
                    <>
                      <div className="mt-10">
                        <h3 className="text-2xl text-[#4D4D4D] font-bold my-5">
                          Informações adicionais da unidade
                        </h3>
                        <p
                          className="text-base font-[500]"
                          dangerouslySetInnerHTML={{ __html: selectedUnit.AdditionalInfo }}
                        ></p>
                      </div>
                    </>
                  )}
                  <ModalPresentation
                    imgUrl={selectedImgModal}
                    showModal={showModal}
                    closeModal={() => setShowModal(false)}
                  />
                </section>
              )}
            </div>
          </section>
          <hr className=" z-[-1] relative opacity-10 mt-4" />
        </>
      ) : (
        <></>
      )}
    </>
  )
}
