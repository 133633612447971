import React, { useCallback } from 'react'
import BannerNotFound from '../../../assets/Image-not-found.webp'
import SkeletonLoading from '../../common/SkeletonLoading'
import { Suspense, memo } from 'react'
import { isValidStr } from '../../../common_functions'
import { useNavigate } from 'react-router-dom'

interface Props {
  link?: string
  bannerDesktopUrl?: string
  bannerMobileUrl?: string
}

function CardPatrocinado({ link, bannerDesktopUrl, bannerMobileUrl }: Props) {
  const navigation = useNavigate()
  const handleToPage = useCallback(() => {
    // if (link != undefined) window.open(link, "_blank")
    if(link != undefined) navigation(link)
  }, [window.location])

  return (
    <>
      <div className="text-center">
        <button
          value="Ver Patrocinado"
          disabled={!isValidStr(link)}
          onClick={handleToPage}
          className="w-full"
        >
          {/* {width <= 767 ? (
            <img src={bannerMobileUrl ?? BannerPatrocinadoMobile} width={300} height={120} className="banner-patrocinado-mobile w-[300px] h-[120px]" alt='Banner Patrocinado Mobile' />
          ) : (
            <img src={bannerDesktopUrl ?? BannerPatrocinado} width={1200} height={200} className="max-w-[1440px] h-auto w-full m-auto" alt='Banner Patrocinado' />
          )} */}
          {/* <Suspense fallback={<SkeletonLoading height={200} />}>
            <div
              className="bg-cover bg-center max-w-[1440px] h-auto md:w-full m-auto banner-patrocinado"
              style={{
                backgroundImage: `url('${bannerDesktopUrl ?? BannerNotFound}')`,
                aspectRatio: '4/1.5'
              }}
            ></div>
          </Suspense> */}
          {window.innerWidth > 767 ? (
            <img
              src={bannerDesktopUrl ?? BannerNotFound}
              width="300px"
              loading='lazy'
              className="max-w-[1440px] h-auto md:w-full m-auto banner-patrocinado"
              alt="Banner Patrocinado"
            />
          ) : (
            <img
              src={bannerMobileUrl ?? bannerDesktopUrl ?? bannerMobileUrl}
              width="300px"
              loading='lazy'
              className="max-w-[1440px] h-auto md:w-full m-auto banner-patrocinado"
              alt="Banner Patrocinado"
            />
          )}
        </button>
      </div>
    </>
  )
}
export default memo(CardPatrocinado)
