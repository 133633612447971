import { lazy, useEffect, useState } from 'react'
import enterpriseApi from '../../store/reducers/enterprise/enterpriseApi'
import SkeletonLoading from '../common/SkeletonLoading'
import { IconTitle } from '../../interfaces/enterprise/enterpriseIconTitle'

const IconsText = lazy(() => import('./IconsText'))

interface Props {
  EnterpriseId: string
}

export default function Proximities({ EnterpriseId }: Props) {
  const { data, isError, isSuccess, isLoading } =
    enterpriseApi.useGetEnterpriseProximitiesByIdQuery(EnterpriseId ?? '')

  const [dataIcons, setDataIcons] = useState<IconTitle[]>()

  useEffect(() => {
    if(isSuccess && data?.Data.length > 0){
      var listIcons:IconTitle[] = []
      data?.Data.forEach(element => {
        listIcons.push({Icon: element.Proximity.Icon, Title: element.Proximity.Name ?? ""})
      });
      setDataIcons(listIcons)
    }
  }, [isSuccess])

  return (
    <>
      {isError && (
        <div className="alert alert-danger my-3" role="alert">
          Houve um erro ao carregar essa sessão do empreendimento, tente novamente mais tarde!
        </div>
      )}
      {isLoading && <SkeletonLoading height={100} />}
      {isSuccess && dataIcons != undefined &&  <IconsText hrMargin
                              IconsTextList={dataIcons}
                              idHTML="navInternEnterpriseProximities"
                              title={'Proximidades'}/>}
    </>
  )
}
