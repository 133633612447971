import { Suspense } from 'react'
import enterpriseApi from '../../store/reducers/enterprise/enterpriseApi'
import SkeletonLoading from '../common/SkeletonLoading'

interface Props {
  EnterpriseId: string
}

export default function Video({ EnterpriseId }: Props) {
  const { data, isError, isSuccess, isLoading } =
    enterpriseApi.useGetEnterpriseMediasByIdQuery(EnterpriseId ?? '')

  const getLinkEmbed = (link:string) => {
    if(link.includes("embed")){
      return link
    }else if(link.includes("watch")){
      return link.replace("watch?", "embed/").replace("v=", "")
    }else{
      return link.slice(0, link.indexOf("?")).replace("youtu.be/", "youtube.com/embed/")
    }
  }
  return (
    <>
      {isLoading && <SkeletonLoading height={200} />}
      {isSuccess && (data?.Data.filter(x => x.Category.Enumerator == 5).length ?? 0) > 0 && (
        <div>
          <h3 className="font-bold text-xl md:text-2xl my-5 text-[#4D4D4D]">
            Vídeo
          </h3>
          <div>
            <Suspense fallback={<SkeletonLoading  height={200}/>}>
              <iframe
                width="100%"
                className="aspect-[16/9]"
                src={getLinkEmbed(data?.Data.filter(x => x.Category.Enumerator == 5)[0].FileUrl)}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </Suspense>
          </div>
          <hr className=" z-[-1] relative opacity-10 mt-4" />
        </div>
      )}
    </>
  )
}
