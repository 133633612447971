import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { IDefaultResponse } from '../../../interfaces/api/response'
import { DataRTKReturn, DataRTKReturnList } from '../../../interfaces/dataRTKReturn'
import { IBuilder, IBuilderEvaluation, IBuilderReview } from '../../../interfaces/builder/builder'

const builderApi = createApi({
  reducerPath: 'builderApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/glemo` }),
  tagTypes: ['builders'],
  endpoints: (builder) => ({
    getDetails: builder.mutation<DataRTKReturn<IBuilder>, string>({
      query: (id) => ({
        url: `/construction-company/detail`,
        body: JSON.stringify({
          "id": id
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['builders']
    }),
    getCommentBuilder: builder.mutation<DataRTKReturnList<IBuilderEvaluation>, string>({
      query: (enterpriseId) => ({
        url: `/assesment-construction-company`,
        body: JSON.stringify({
          "enterpriseId": enterpriseId
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['builders']
    }),
    getReviewsBuilder: builder.mutation<DataRTKReturn<IBuilderReview>, string>({
      query: (constructionCompanyId) => ({
        url: `/assesment-construction-company-reviews`,
        body: JSON.stringify({
          "constructionCompanyId": constructionCompanyId
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['builders']
    }),
  })
})

export default builderApi
