import React, { useState, useEffect } from 'react'
import { isValidStr } from '../../../common_functions'

type Recognizer = SpeechRecognition | null

interface Props {
  startListen?: boolean
  stopListen?: boolean
  stopTranscript?: () => void
  handleAllowed?: (allowed: boolean) => void
  transcriptListen: (val: string) => void
}

export default function VoiceToText({
  startListen,
  stopListen,
  transcriptListen,
  handleAllowed,
  stopTranscript
}: Props) {
  const [transcript, setTranscript] = useState<string>('')
  const [listening, setListening] = useState<boolean>(false)
  const [allowed, setAllowed] = useState(true)
  let recognition: Recognizer = null

  useEffect(() => {
    if (startListen) startListening()
  }, [startListen])

  useEffect(() => {
    if (stopListen) stopListening()
  }, [stopListen])

  useEffect(() => {
    transcriptListen(transcript)
  }, [transcript])

  // Função para iniciar o reconhecimento de fala
  const startListening = () => {
    const SpeechRecognition =
      (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition

    if (!SpeechRecognition) {
      console.error('SpeechRecognition not supported by this browser.')
      return
    }

    recognition = new SpeechRecognition()
    if (recognition != null) {
      recognition.lang = 'pt-BR'
      recognition.interimResults = false
      recognition.maxAlternatives = 1

      recognition.onstart = () => {
        setListening(true)
      }

      recognition.onresult = (event: SpeechRecognitionEvent) => {
        let newTranscript = ''
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            newTranscript += event.results[0][0].transcript + ' '
          }
        }
        setTranscript(newTranscript)
        transcriptListen(newTranscript)
      }

      recognition.onerror = (event: SpeechRecognitionErrorEvent) => {
        console.error('Recognition error: ', event.error)
        if (event.error.includes('not-allowed')) {
          if (handleAllowed != undefined) handleAllowed(false)
          setAllowed(false)
        }
      }

      recognition.onend = () => {
        setListening(false)
        if(stopTranscript) stopTranscript()
      }

      recognition.start()
    }
  }

  const requestMicrophonePermission = async () => {
    try {
      // Solicita o acesso ao microfone
      await navigator.mediaDevices.getUserMedia({ audio: true })
      // O usuário concedeu a permissão
      if (handleAllowed != undefined) handleAllowed(true)
      setAllowed(true)
    } catch (err) {
      // O usuário recusou a permissão, ou ocorreu um erro
      if (handleAllowed != undefined) handleAllowed(false)
      setAllowed(false)
      console.error(err)
    }
  }

  useEffect(() => {
    if (!allowed) {
      requestMicrophonePermission()
    }
  }, [allowed])

  // Função para parar o reconhecimento de fala
  const stopListening = () => {
    if (isValidStr(transcript)) {
      if (recognition) {
        recognition.stop()
        setListening(false)
      }
      transcriptListen(transcript)
    } else {
      setTimeout(() => {}, 1000)
    }
  }

  useEffect(() => {
    const SpeechRecognition =
      (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition

    if (SpeechRecognition) {
      recognition = new SpeechRecognition()
    } else {
      console.error('SpeechRecognition is not supported by this browser.')
    }
  }, [])

  return (
    <></>
  )
}
