import React, { useLayoutEffect } from 'react'
import { RequestCampaignDay } from '../../../interfaces/api/request'
import campaignApi from '../../../store/reducers/campaigns/campaignApi'
import CardPatrocinado from '../CardPatrocinado'
import SkeletonLoading from '../../common/SkeletonLoading'

interface Props {
  position: number
  constructionCompanyId?: string | null
}

export default function CardPatrocinadoByIndex({ position, constructionCompanyId = null }: Props) {
  const [
    useCampaigns,
    {
      data: dataCampaigns,
      isError: isErrorCampaigns,
      isSuccess: isSuccessCampaigns,
      isLoading: isLoadingCampaigns
    }
  ] = campaignApi.useGetCampaignsDayMutation()

  const getCampaignsDay = async () => {
    const content: RequestCampaignDay = {
      date: formatDate(new Date()),
      constructionCompanyId: constructionCompanyId
    }
    await useCampaigns(content)
  }

  function formatDate(date: Date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // meses começam em 0
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0')

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`
  }

  useLayoutEffect(() => {
    getCampaignsDay()
  }, [])

  const getCardPatrocinado = (index: number) => {
    if (isSuccessCampaigns) {
      var campaigns = undefined
      if (index === 0) {
        campaigns = dataCampaigns?.Data.filter((x) => x.PositionSearch == 1)
      } else if (index === 1) {
        campaigns = dataCampaigns?.Data.filter((x) => x.PositionSearch == 2)
      } else if (index == 2) {
        campaigns = dataCampaigns?.Data.filter((x) => x.PositionSearch == 3)
      }

      if (campaigns != undefined && campaigns.length > 0) {
        var campaign = campaigns[0]
        return (
          <CardPatrocinado
            bannerDesktopUrl={campaign.BannerDesktopUrl}
            bannerMobileUrl={campaign.BannerMobileUrl}
            link={`/empreendimentos/${campaign.Slug}`}
          />
        )
      } else {
        return <></>
      }
    }
  }

  return (
    <>
      {isLoadingCampaigns && <SkeletonLoading height={100} />}
      {isSuccessCampaigns && <>{getCardPatrocinado(position - 1)}</>}
    </>
  )
}
