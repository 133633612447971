import React from 'react'
import enterpriseApi from '../../../store/reducers/enterprise/enterpriseApi'
import { Suspense, lazy, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Enterprise2 } from '../../../interfaces/enterprise/enterprise_2'
import { Helmet } from 'react-helmet'
import { IContentCard } from '../../../interfaces/enterprise/argsApi'

import { isMobile, isValidStr } from '../../../common_functions'
import NewCarouselEnterprise from '../../../components/Carousels/NewCarouselEnterprise'
import SecionRecommendedEnterprises from '../../../components/Sections/SecionRecommendedEnterprises'
import {
  INFOS_CARD_SELECTED_ENTERPRISE,
  RECENTS_VIEWED_ENTERPRISES,
  VIEWED_ENTERPRISES
} from '../../../constants/storageNames'
import NavInternEnterprise from '../../../components/NavInternEnterprise'
import CarouselEnterpriseModalMobile from '../../../components/Carousels/CarouselEnterpriseModalMobile'
import { CardEnterpriseResumeInfosInitPage } from '../../../interfaces/enterprise'
import { InternEnterpriseInit } from '../../../components/InternEnterprise/InternEnterpriseInit'
import BaseInfos from '../../../components/InternEnterprise/BaseInfos'

const Header = lazy(() => import('../../../components/InternEnterprise/Header'))
const CardEnterpriseInternInfosPrice = lazy(
  () => import('../../../components/Cards/CardEnterpriseInternInfosPrice')
)
const Error = lazy(() => import('../../../components/Error'))
const SkeletonLoading = lazy(() => import('../../../components/common/SkeletonLoading'))

interface Props {
  isLoadingFull?: boolean
}
export function InternEnterprise({ isLoadingFull = true }: Props) {
  const [enterpriseGeneralInfos, setenterpriseGeneralInfos] = useState<Enterprise2>()
  const [useEnterprises, { data, isError, isLoading, isSuccess }] =
    enterpriseApi.useGetCardsEnterprisesMutation()
  const param = useParams()

  const {
    data: dataEnterpriseData,
    isError: isErrorEnterpriseData,
    isLoading: isLoadingEnterpriseData,
    isSuccess: isSuccessEnterpriseData
  } = enterpriseApi.useGetEnterpriseDataBySlugQuery(param.slug?.toString() ?? '')

  const listStorage = JSON.parse(localStorage.getItem(VIEWED_ENTERPRISES) ?? '[]')
  const [recentsViewedEnterprises, setrecentsViewedEnterprises] = useState<string[]>(
    JSON.parse(sessionStorage.getItem(RECENTS_VIEWED_ENTERPRISES) ?? '[]')
  )

  const [
    useViewedEnterprises,
    { isSuccess: isSuccessViewed, isError: isErrorViewed, isLoading: isLoadingViewed }
  ] = enterpriseApi.useAddViewedEnterpriseMutation()

  const setViewed = async () => {
    await useViewedEnterprises(dataEnterpriseData?.Data.Id ?? '')
  }

  useEffect(() => {
    if (enterpriseGeneralInfos === undefined && isLoadingFull) {
      getEnterprises()
    }
  }, [isLoadingFull])

  useEffect(() => {
    if (isSuccessViewed) {
      addStorageSlug()
    }
  }, [isSuccessViewed])

  const addStorageSlug = () => {
    if (isValidStr(param.Slug)) {
      let list: Array<string> = []
      const listStorage = localStorage.getItem(VIEWED_ENTERPRISES)
      list = JSON.parse(listStorage ?? '[]')
      list.push(param.Slug ?? '')

      localStorage.setItem(VIEWED_ENTERPRISES, listToStr(list))
    }
  }

  useEffect(() => {
    sessionStorage.setItem(
      RECENTS_VIEWED_ENTERPRISES,
      JSON.stringify(recentsViewedEnterprises ?? '[]')
    )
  }, [recentsViewedEnterprises])

  const addStorageRecentsViewed = () => {
    const id = dataEnterpriseData?.Data.Id
    if (id != undefined && !recentsViewedEnterprises.includes(id)) {
      setrecentsViewedEnterprises((prevState) => [...prevState, id])
    }
  }

  const listToStr = (list: string[]) => {
    let str = '['
    list?.forEach((element, index) => {
      str += `"${element}"`
      if (index != list.length - 1) str += ','
    })
    str += ']'
    return str
  }

  useEffect(() => {
    if (isSuccessEnterpriseData && isLoadingFull) {
      if (!listStorage.includes(param.Slug)) setViewed()
      addStorageRecentsViewed()
    }
  }, [isSuccessEnterpriseData, isLoadingFull])

  const getEnterprises = async () => {
    const content: IContentCard = {
      stateId: null,
      cityId: null,
      statusId: null,
      districtId: null,
      priceMaximumValue: null,
      priceMinimumValue: null,
      numberOfRooms: null
    }
    await useEnterprises(content)

    if (!isError && !isLoading && data != undefined) {
      let dataBySlug = data.Data.filter((item: Enterprise2) => item.Slug == param.Slug)
      setenterpriseGeneralInfos(dataBySlug.length > 0 ? dataBySlug[0] : undefined)
    }
  }

  return (
    <>
      {isLoadingFull ? (
        <>
          {
            <div className="lg:pt-5">
              <Helmet>
                <title>glemO | {dataEnterpriseData?.Data?.Title ?? ''}</title>
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {isLoadingEnterpriseData && <SkeletonLoading height={400} />}
              {isSuccessEnterpriseData ? (
                <>
                  <Suspense fallback={<SkeletonLoading />}>
                    {isMobile() ? (
                      <div className="px-5">
                        <CarouselEnterpriseModalMobile EnterpriseId={dataEnterpriseData?.Data.Id} />
                      </div>
                    ) : (
                      // <NewCarouselEnterpriseMobile EnterpriseId={dataEnterpriseData?.Data.Id} />
                      <NewCarouselEnterprise EnterpriseId={dataEnterpriseData?.Data.Id} />
                    )}
                  </Suspense>
                  <section className="container-responsive-view gap-4 lg:mt-10">
                    <div className="lg:grid lg:grid-cols-3">
                      <div className="lg:col-span-3">
                        <div className="lg:grid lg:grid-cols-3">
                          {/* INFORMACOES  */}
                          <div className="lg:pl-10 order-2">
                            <Suspense fallback={<SkeletonLoading />}>
                              <CardEnterpriseInternInfosPrice
                                ConstructionId={
                                  dataEnterpriseData?.Data?.ConstructionCompanyId ?? ''
                                }
                                EnterpriseId={dataEnterpriseData?.Data?.Id ?? ''}
                                Price={dataEnterpriseData?.Data?.PriceMinimumValue}
                                CompanyName={dataEnterpriseData?.Data?.ConstructionCompany}
                                LogoUrl={dataEnterpriseData?.Data?.ConstructionCompanyLogoUrl}
                                LastPublish={dataEnterpriseData?.Data?.LastPublish}
                                DescriptionCompany={
                                  dataEnterpriseData?.Data?.ConstructionCompanyDescription
                                }
                              />
                            </Suspense>
                          </div>
                          <div
                            className="lg:col-span-2 lg:pr-10 mt-3 lg:mt-auto"
                            style={{ overflowWrap: 'break-word' }}
                          >
                            <div>
                              {/* HEADER */}
                              <Suspense fallback={<SkeletonLoading />}>
                                <Header
                                  StatusId={dataEnterpriseData?.Data?.StatusId}
                                  Id={dataEnterpriseData?.Data?.Id}
                                  Title={dataEnterpriseData?.Data?.Title}
                                  Description={dataEnterpriseData?.Data?.Description}
                                />
                              </Suspense>
                              <NavInternEnterprise enterprise={dataEnterpriseData?.Data} />
                            </div>
                          </div>
                        </div>
                        {/* RELACIONADOS */}
                        <SecionRecommendedEnterprises enterpriseId={dataEnterpriseData?.Data.Id} />
                      </div>
                    </div>
                  </section>
                </>
              ) : (
                <>
                  {isErrorEnterpriseData ? (
                    <>
                      <Error
                        typeError="500"
                        descriptionError="Fique tranquilo, estamos cientes e trabalhando para criar algo melhor.
            Sugerimos que você tente atualizar a página novamente, se o erro persistir, tente novamente mais tarde."
                      />
                    </>
                  ) : (
                    <div className={`${isMobile() ? 'mt-[13rem]' : ''}`}>
                      <div id="loadingBox"></div>
                    </div>
                  )}
                </>
              )}
            </div>
          }
        </>
      ) : (
        <>
          <InternEnterpriseInit
            dataEnterprise={dataEnterpriseData?.Data}
            isLoadingEnterpriseData={isLoadingEnterpriseData}
            isSuccessEnterpriseData={isSuccessEnterpriseData}
          />
        </>
      )}
    </>
  )
}
