import React, { useEffect, useState } from 'react'

import { v4 } from 'uuid'

import { BannerCarouselInternEnterprise } from '../../Banners/BannerCarouselInternEnterprise'
import NotFoundImg from '../../../assets/Image-not-found.webp'
import enterpriseApi from '../../../store/reducers/enterprise/enterpriseApi'
import SkeletonLoading from '../../common/SkeletonLoading'
import FavoriteEnterprise from '../../FavoriteEnterprise'
import { EnterprisePhotos } from '../../../interfaces/enterprise/enterprisePhotos'
import { isValidStr } from '../../../common_functions'
import { useSwipeable } from 'react-swipeable'
import { ModalCarouselEnterpriseMobile } from '../../Modais/ModalCarouselEnterpriseMobile'

interface Props {
  EnterpriseId: string
}

let id = v4()

interface Item {
  FileUrl: string
}

export default function CarouselEnterpriseModalMobile({ EnterpriseId }: Props) {
  const { data, isSuccess, isError, isLoading } =
    enterpriseApi.useGetEnterprisesMediasPhotosByIdQuery(EnterpriseId ?? '')
  const [bannerUrls, setBannerUrls] = useState<{ [key: string]: string }>({})
  const [currentBannerIndex, setCurrentBannerIndex] = useState<number>(0)
  const [showModal, setShowModal] = useState(false)

  // useEffect(() => {
  //   let body: HTMLElement | null = document.querySelector('body')

  //   if (body != null) {
  //     if (showModal) {
  //       body.setAttribute('style', 'overflow-y: hidden')
  //       body.classList.add("overflow-y-hidden")
  //     } else {
  //       body.classList.remove("overflow-y-hidden")
  //       body.setAttribute('style', 'overflow-y: auto')
  //     }
  //   }
  // }, [showModal])

  const lockScroll = () => {
    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollBarWidth}px`;
    document.body.style.overflow = 'hidden';
  };

  const unlockScroll = () => {
    document.body.style.paddingRight = '';
    document.body.style.overflow = '';
  };

  useEffect(() => {
    if (showModal) {
      lockScroll();
    } else {
      unlockScroll();
    }

    return () => {
      unlockScroll();
    };
  }, [showModal]);

  useEffect(() => {
    const headerHTML = document.querySelector(".header-mobile") as HTMLElement
    const cardPriceHTML = document.querySelector(".card-enterprise-intern-infos-prices") as HTMLElement
    if(headerHTML){
      if(showModal){
        headerHTML.classList.remove("z-[99]")
      }else{
        headerHTML.classList.add("z-[99]")
      }
    }
    if(cardPriceHTML){
      if(showModal){
        cardPriceHTML.classList.add("hidden")
      }else{
        cardPriceHTML.classList.remove("hidden")
      }
    }
  }, [showModal])

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextBannerCarousel(),

    onSwipedRight: () => handlePrevBannerCarousel()
  })

  const handleNextBannerCarousel = () => {
    if (currentBannerIndex < (data?.Data?.length ?? 0) - 1)
      setCurrentBannerIndex(currentBannerIndex + 1)
  }

  const handlePrevBannerCarousel = () => {
    if (currentBannerIndex != 0) setCurrentBannerIndex(currentBannerIndex - 1)
  }

  return (
    <div key={EnterpriseId} className="carousel" {...handlers}>
      {isLoading && <SkeletonLoading height={300} />}
      {isSuccess && data?.Data?.length > 0 && (
        <>
          {/* mainbanner */}
          <div>
            {!isValidStr(data?.Data[currentBannerIndex].FileUrl) ? (
              <SkeletonLoading className="aspect-[4/2]" />
            ) : (
              <div
                style={{
                  backgroundImage: `url('${data?.Data[currentBannerIndex].FileUrl ?? ''}')`
                }}
                onClick={() => setShowModal(true)}
                className="w-full aspect-[360/290] bg-no-repeat bg-cover bg-center mt-10"
              >
                <div className="grid grid-cols-2 pt-4 px-2">
                  <div className="col-span-1 text-start">
                    <div className="bg-[#F0F3F6] rounded h-full text-center m-auto ms-0 w-fit px-2 cursor-pointer">
                      <a href="/">
                        <svg
                          width="20"
                          height="14"
                          viewBox="0 0 20 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="inline-block"
                          style={{ verticalAlign: 'baseline' }}
                        >
                          <path
                            d="M7 14L8.41 12.59L3.83 8H20V6H3.83L8.42 1.41L7 0L0 7L7 14Z"
                            fill="#0059FF"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                  <div className="co-span-1 text-right">
                    <div className="min-w-[50px] m-auto float-right">
                      <FavoriteEnterprise enterpriseId={EnterpriseId} />
                    </div>
                  </div>
                </div>
                <div className="absolute top-[437px] ps-3">
                  <button
                    onClick={() => setShowModal(true)}
                    type="button"
                    className="bg-[#0A0A0ACC] text-white px-1 text-center align-middle rounded-sm"
                  >
                    <div className='-mt-1'>
                      <span className="m-auto inline-block">
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          className="inline-block -mt-2"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.5 12.6667C10.8117 12.6667 11.875 11.6035 11.875 10.2917C11.875 8.98004 10.8117 7.91675 9.5 7.91675C8.18829 7.91675 7.125 8.98004 7.125 10.2917C7.125 11.6035 8.18829 12.6667 9.5 12.6667Z"
                            stroke="white"
                            stroke-width="1.58333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M2.375 13.3001V7.28341C2.375 6.39667 2.375 5.95329 2.54758 5.6146C2.69937 5.31667 2.94159 5.07445 3.23952 4.92266C3.57821 4.75008 4.02159 4.75008 4.90833 4.75008H5.74326C5.84058 4.75008 5.88925 4.75008 5.93414 4.74494C6.1684 4.71811 6.3785 4.58826 6.50725 4.39074C6.53193 4.35288 6.5537 4.30934 6.59722 4.2223C6.68427 4.04821 6.7278 3.96115 6.77716 3.88544C7.03467 3.4904 7.45487 3.2307 7.9234 3.17703C8.01317 3.16675 8.11047 3.16675 8.30514 3.16675H10.6949C10.8895 3.16675 10.9868 3.16675 11.0766 3.17703C11.5451 3.2307 11.9653 3.4904 12.2229 3.88544C12.2722 3.96115 12.3157 4.04823 12.4028 4.2223C12.4463 4.30935 12.468 4.35288 12.4927 4.39074C12.6215 4.58826 12.8316 4.71811 13.0658 4.74494C13.1108 4.75008 13.1594 4.75008 13.2568 4.75008H14.0917C14.9784 4.75008 15.4218 4.75008 15.7605 4.92266C16.0584 5.07445 16.3007 5.31667 16.4524 5.6146C16.625 5.95329 16.625 6.39667 16.625 7.28341V13.3001C16.625 14.1868 16.625 14.6302 16.4524 14.9689C16.3007 15.2668 16.0584 15.5091 15.7605 15.6608C15.4218 15.8334 14.9784 15.8334 14.0917 15.8334H4.90833C4.02159 15.8334 3.57821 15.8334 3.23952 15.6608C2.94159 15.5091 2.69937 15.2668 2.54758 14.9689C2.375 14.6302 2.375 14.1868 2.375 13.3001Z"
                            stroke="white"
                            stroke-width="1.58333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      <span className="ps-2 text-2xl">{data?.Data?.length}</span>
                    </div>
                  </button>
                </div>
              </div>
            )}
          </div>
          <ModalCarouselEnterpriseMobile
            photos={data?.Data}
            showModal={showModal}
            closeModal={() => setShowModal(false)}
          />
        </>
      )}
    </div>
  )
}
