import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { IContentGetEnterpriseCards } from './interfaces/IContentApis'
import { Enterprise2 } from '../../../interfaces/enterprise/enterprise_2'
import { EnterpriseDetail } from '../../../interfaces/enterprise/entepriseDetail'
import { DataRTKReturn, DataRTKReturnList } from '../../../interfaces/dataRTKReturn'
import {
  IContentCard,
  IContentCardDistrictCityStateId
} from '../../../interfaces/enterprise/argsApi'
import { IDefaultResponse } from '../../../interfaces/api/response'
import { IResponseError, IResponseErrorNotFound } from '../interfaces/IResponseApi'
import { EnterpriseGlobalDifferential } from '../../../interfaces/enterprise/enterpriseDifferentials'
import { EnterpriseGlobalProximity } from '../../../interfaces/enterprise/enterpriseProximities'
import { EnterprisePhotos } from '../../../interfaces/enterprise/enterprisePhotos'
import { EnterpriseTipologies } from '../../../interfaces/enterprise/enterpriseTipologies'
import { EnterpriseTag } from '../../../interfaces/enterprise/enterpriseTags'
import { EnterprisePaymentCondition } from '../../../interfaces/enterprise/enterprisePaymentCondition'
import { EnterpriseInformation } from '../../../interfaces/enterprise/enterpriseInformation'
import { EnterpriseAddress } from '../../../interfaces/enterprise/enterpriseAddress'
import { EnterpriseAdjacentDistrict } from '../../../interfaces/enterprise/enterpriseAdjacentDistrict'
import { EnterpriseAdjacentCity } from '../../../interfaces/enterprise/enterpriseAdjacentCity'
import { EnterpriseData } from '../../../interfaces/enterprise/enterpriseData'
import { EnteprisePresentationFiles } from '../../../interfaces/enterprise/enterprisePresentationFiles'
import { EnteprisePresentationMedia } from '../../../interfaces/enterprise/enterprisePresentationMedia'
import { ENUM_PHOTO_AREA_LOCATION, ENUM_PHOTO_BANNER } from '../../../constants/enterprisePresentationMediaTypesEnums'
import { EnterpriseUnit } from '../../../interfaces/enterprise/enterpriseUnit'
import { RequestCardsByArgumentPageIndex, RequestCardsByArgumentsAutocomplete } from '../../../interfaces/api/request'

const photosCategoryId = "e4d0a9b2-9080-4996-ac7b-416241700193"
const mainPhotoCategoryId = "260fd25d-44fb-4073-8ef4-17b567a49dbe"
const plantCategoryId = "cd4dd752-7dc2-4f04-8c0e-2658dba81cb5"

const enterpriseApi = createApi({
  reducerPath: 'enterpriseApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/glemo` }),
  tagTypes: ['enterprises'],
  endpoints: (builder) => ({
    getCardsEnterprises: builder.mutation<DataRTKReturnList<Enterprise2>, IContentCard>({
      query: (content) => ({
        url: `/enterprises/cards-by-filters`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['enterprises'],
      transformErrorResponse: (response: IResponseError<IResponseErrorNotFound>, meta, arg) => {
        return response
      }
    }),
    getCardsEnterprisesCityStateDistrictId: builder.mutation<
      DataRTKReturnList<Enterprise2>,
      IContentCardDistrictCityStateId
    >({
      query: (content) => ({
        url: `/enterprises/cards-by-filters`,
        body: JSON.stringify({
          stateId: content.stateId,
          cityId: content.cityId,
          districtId: content.districtId,
          statusId: null,
          priceMinimumValue: null,
          priceMaximumValue: null,
          numberOfRooms: null
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['enterprises'],
      transformErrorResponse: (response: IResponseError<IResponseErrorNotFound>, meta, arg) => {
        return response
      }
    }),
    getRecommendedEnterprises: builder.mutation<DataRTKReturnList<Enterprise2>, string>({
      query: (id) => ({
        url: `/enterprises/recommended`,
        method: 'POST',
        body: JSON.stringify({
          enterpriseId: id
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      transformErrorResponse: (response: IResponseError<IResponseErrorNotFound>, meta, arg) => {
        return response
      }
    }),
    getCardsEnterprisesConstructionId: builder.mutation<
      DataRTKReturnList<Enterprise2>,
      string
    >({
      query: (cityId) => ({
        url: `/enterprises/cards-by-filters`,
        body: JSON.stringify({
          stateId: null,
          cityId: null,
          constructionCompanyId: cityId,
          districtId: null,
          statusId: null,
          priceMinimumValue: null,
          priceMaximumValue: null,
          numberOfRooms: null
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['enterprises'],
      transformErrorResponse: (response: IResponseError<IResponseErrorNotFound>, meta, arg) => {
        return response
      }
    }),
    getCardsEnterprisesCityId: builder.mutation<
      DataRTKReturnList<Enterprise2>,
      string
    >({
      query: (cityId) => ({
        url: `/enterprises/cards-by-filters`,
        body: JSON.stringify({
          stateId: null,
          cityId: cityId,
          districtId: null,
          statusId: null,
          priceMinimumValue: null,
          priceMaximumValue: null,
          numberOfRooms: null
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['enterprises'],
      transformErrorResponse: (response: IResponseError<IResponseErrorNotFound>, meta, arg) => {
        return response
      }
    }),
    getCardsEnterprisesStateId: builder.mutation<
      DataRTKReturnList<Enterprise2>,
      string
    >({
      query: (stateId) => ({
        url: `/enterprises/cards-by-filters`,
        body: JSON.stringify({
          stateId: stateId,
          cityId: null,
          districtId: null,
          statusId: null,
          priceMinimumValue: null,
          priceMaximumValue: null,
          numberOfRooms: null
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['enterprises'],
      transformErrorResponse: (response: IResponseError<IResponseErrorNotFound>, meta, arg) => {
        return response
      }
    }),
    getEnterprisesByFullText: builder.mutation<DataRTKReturnList<Enterprise2>, RequestCardsByArgumentPageIndex>({
      query: (content) => ({
        url: `/products/search-by-argument`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      transformErrorResponse: (response: IResponseError<IResponseErrorNotFound>, meta, arg) => {
        return response
      }
    }),
    getEnterprisesByFullTextAutocomplete: builder.mutation<DataRTKReturnList<Enterprise2>, RequestCardsByArgumentsAutocomplete>({
      query: (content) => ({
        url: `/products/search-by-argument`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      transformErrorResponse: (response: IResponseError<IResponseErrorNotFound>, meta, arg) => {
        return response
      }
    }),
    getAllCardsEnterprises: builder.mutation<DataRTKReturnList<Enterprise2>, number|undefined>({
      query: (pageIndex) => ({
        url: `/enterprises/cards-by-filters`,
        body: JSON.stringify({
          stateId: null,
          cityId: null,
          districtId: null,
          statusId: null,
          priceMinimumValue: null,
          priceMaximumValue: null,
          numberOfRooms: null,
          pageIndex: pageIndex,
          pageSize: 9
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getEnterpriseDetailsById: builder.mutation<DataRTKReturn<EnterpriseDetail>, string>({
      query: (id) => ({
        url: `/enterprises/detailed-by-id`,
        body: JSON.stringify({
          id: id
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    addViewedEnterprise: builder.mutation<IDefaultResponse, string>({
      query: (id) => ({
        url: `/enterprises/viewed/add`,
        body: JSON.stringify({
          enterpriseId: id
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getEnterpriseDetailsBySlug: builder.query<DataRTKReturn<EnterpriseDetail>, string>({
      query: (slug) => ({
        url: `/enterprises/detailed-by-slug/${slug}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getEnterpriseDataBySlug: builder.query<DataRTKReturn<EnterpriseData>, string>({
      query: (slug) => ({
        url: `/enterprises/data?slug=${slug}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getEnterprisesDataById: builder.query<DataRTKReturn<EnterpriseData>, string>({
      query: (enterpriseId) => ({
        url: `/enterprises/data?enterpriseId=${enterpriseId}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getEnterpriseDifferentialsById: builder.query<DataRTKReturnList<EnterpriseGlobalDifferential>, string>({
      query: (id) => ({
        url: `/enterprises/differentials/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getEnterpriseProximitiesById: builder.query<DataRTKReturnList<EnterpriseGlobalProximity>, string>({
      query: (id) => ({
        url: `/enterprises/proximities/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getEnterprisesMediasPhotosById: builder.query<DataRTKReturnList<EnterprisePhotos>, string>({
      query: (id) => ({
        url: `/enterprises/medias/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      transformResponse: (response:DataRTKReturnList<EnterprisePhotos>) => {
        let dataAux:Array<EnterprisePhotos> = []
        let newResponse:DataRTKReturnList<EnterprisePhotos> = response
        if(response.Data.filter(x => x.MainPhoto).length > 0) dataAux.push(response.Data.filter(x => x.MainPhoto)[0])
        response.Data.forEach(element => {
          if(element.Category.Enumerator == ENUM_PHOTO_AREA_LOCATION){
            dataAux.push(element)
          }
        });
        newResponse.Data = dataAux
        return newResponse
      }
    }),
    getEnterpriseMediasById: builder.query<DataRTKReturnList<EnterprisePhotos>, string>({
      query: (id) => ({
        url: `/enterprises/medias/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getEnterpriseTipologiesById: builder.query<DataRTKReturnList<EnterpriseTipologies>, string>({
      query: (id) => ({
        url: `/enterprises/tipologies/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getEnterpriseAdjacentsCitiesById: builder.query<DataRTKReturnList<EnterpriseAdjacentCity>, string>({
      query: (id) => ({
        url: `/enterprises/adjacents/cities/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getEnterpriseAdjacentsDistrictsById: builder.query<DataRTKReturnList<EnterpriseAdjacentDistrict>, string>({
      query: (id) => ({
        url: `/enterprises/adjacents/districts/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getEnterpriseTagsById: builder.query<DataRTKReturnList<EnterpriseTag>, string>({
      query: (id) => ({
        url: `/enterprises/tags/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getEnterprisePresentationFilesById: builder.query<DataRTKReturnList<EnteprisePresentationFiles>, string>({
      query: (id) => ({
        url: `/enterprises/presentation-files/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getEnterprisePresentationMediaById: builder.query<DataRTKReturn<EnteprisePresentationMedia>, string>({
      query: (id) => ({
        url: `/enterprises/presentation-media/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getEnterprisePaymentConditionById: builder.query<DataRTKReturnList<EnterprisePaymentCondition>, string>({
      query: (id) => ({
        url: `/enterprises/payment-condition/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getEnterpriseInformationById: builder.query<DataRTKReturn<EnterpriseInformation>, string>({
      query: (id) => ({
        url: `/enterprises/information/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getEnterpriseLegalTextById: builder.query<DataRTKReturn<EnterpriseDetail>, string>({
      query: (id) => ({
        url: `/enterprises/legal-text/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getEnterpriseAddressById: builder.query<DataRTKReturn<EnterpriseAddress>, string>({
      query: (id) => ({
        url: `/enterprises/address/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getUnits: builder.query<DataRTKReturn<EnterpriseUnit>, string>({
      query: (id) => ({
        url: `/enterprises/info/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
  })
})

export default enterpriseApi
