import React from 'react'
import IconStarFull from '../../assets/icons/icon-star-full.svg'
import IconStarEmpty from '../../assets/icons/icon-star-empty.svg'
import { v4 } from 'uuid'

interface Prop {
  nota: number | undefined,
  size?: number,
  max?: number,
  marginClass?: string
}

export default function Evaluation({ nota = 5, size = 25, max = 5, marginClass = "mx-2" }: Prop) {
  return (
    <div id="evaluationContainer" className='grid grid-cols-5 m-auto w-fit'>
      {Array.from({ length: max }, (_, i) => i + 1).map((el) => {
        let star = el <= nota ? IconStarFull : IconStarEmpty
        return <div key={`${el}${v4()}`} className={`${marginClass}`}><img alt="Star Icon" loading='lazy'  className={`w-auto inline-block  max-w-none`} style={{width: `${size}px`}} src={star}/></div>
      })}
    </div>
  )
}
