import { Suspense } from 'react'
import { EnterpriseData } from '../../interfaces/enterprise/enterpriseData'
import Planta from './Planta'
import Video from './Video'
import SkeletonLoading from '../common/SkeletonLoading'
import Downloads from './Downloads'

interface Props {
  enterprise: EnterpriseData
}

export default function SectionMedia({ enterprise }: Props) {
  return (
    <>
      {/* VIDEO */}
      <Suspense fallback={<SkeletonLoading />}>
        <Video EnterpriseId={enterprise?.Id} />
      </Suspense>
      {/* PLANTA */}
      <Suspense fallback={<SkeletonLoading />}>
        <Planta EntepriseId={enterprise.Id} />
      </Suspense>
    </>
  )
}
