import clsx from 'clsx'
import { useEffect, useState } from 'react'
import React from 'react'
import './TextInputField.css'
import { isValidStr } from '../../../common_functions'
import { memo } from 'react'

interface Props {
  id?: string
  name: string
  label: string
  defaultValue?: string
  required?: boolean
  classNameContainer?: string
  maxLength?: number
  type?: string
  variant?: 'normal' | 'orange' | 'primary'
  roundedBorderLg?: boolean
  border?: string
  maskPhoneNumber?: boolean
  maskCPF?: boolean
  maskCPFCNPJ?: boolean
  maskCNPJ?: boolean
  setInput?: (val: string) => void
  handleKeyDown?: (val: string) => void
  iconImg?: string
  isSpeechBalloon?: boolean
  speechBalloonInfos?: string
  hasViewPassword?: boolean
}

function TextInputField({
  name,
  id,
  label,
  defaultValue,
  classNameContainer,
  required = false,
  maxLength,
  roundedBorderLg = false,
  variant = 'normal',
  type = 'text',
  hasViewPassword,
  maskPhoneNumber = false,
  maskCPF = false,
  maskCNPJ = false,
  maskCPFCNPJ = false,
  border,
  setInput,
  handleKeyDown,
  iconImg,
  isSpeechBalloon = false,
  speechBalloonInfos
}: Props) {
  const [valInput, setValInput] = useState(defaultValue ?? '')
  const [openSpeechBalloon, setopenSpeechBalloon] = useState(false)
  const [classNameLabel, setClassNameLabel] = useState(
    isValidStr(defaultValue) ? 'label-filled' : ''
  )
  const [typeInput, setTypeInput] = useState(type)
  const [classNameInput, setClassNameInput] = useState(
    isValidStr(defaultValue) ? 'input-filled' : ''
  )

  useEffect(() => {
    if (maskCPF && defaultValue != undefined)
      setValInput(maskCPF ? formatCPF(defaultValue) : defaultValue)
  }, [maskCPF, defaultValue])

  useEffect(() => {
    if (maskCPFCNPJ && defaultValue != undefined)
      if (defaultValue.length <= 11) {
        setValInput(maskCPFCNPJ ? formatCPF(defaultValue) : defaultValue)
      } else {
        setValInput(maskCPFCNPJ ? formatCNPJ(defaultValue) : defaultValue)
      }
  }, [maskCPFCNPJ, defaultValue])

  useEffect(() => {
    if (maskCNPJ && defaultValue != undefined)
      setValInput(maskCNPJ ? formatCNPJ(defaultValue) : defaultValue)
  }, [maskCNPJ, defaultValue])

  useEffect(() => {
    if (maskPhoneNumber && defaultValue != undefined)
      setValInput(maskPhoneNumber ? formatPhoneNumber(defaultValue) : defaultValue)
  }, [maskPhoneNumber, defaultValue])

  const handleChangeValueInput = (val: string) => {
    if (setInput != undefined) setInput(val)

    if (maxLength != undefined && val.toString().length > maxLength) {
      return false
    } else {
      if (maskPhoneNumber) setValInput(formatPhoneNumber(val))
      else if (maskCPF) setValInput(formatCPF(val))
      else if (maskCNPJ) setValInput(formatCNPJ(val))
      else if (maskCPFCNPJ) {
        setValInput(val.length <= 11 ? formatCPF(val) : formatCNPJ(val))
      } else setValInput(val)
    }
    if (valInput && isValidStr(valInput)) {
      setClassNameLabel('label-filled')
      setClassNameInput('input-filled')
    } else {
      setClassNameLabel('')
      setClassNameInput('')
    }
  }

  const isValidInput = () => {
    if (type == 'email') {
      if (valInput.includes('@')) {
        return true
      } else {
        return false
      }
    }
    return true
  }

  const handleFocusOut = () => {
    if (required) {
      if (isValidStr(valInput) && isValidInput()) {
        setClassNameLabel('label-filled label-filled-success')
        setClassNameInput('input-filled input-filled-success')
      } else {
        setClassNameLabel('label-filled-error')
        setClassNameInput('input-filled-error')
      }
    }
  }

  const handleKeyDownConst = (e: string) => {
    if (handleKeyDown != undefined) {
      handleKeyDown(e)
    }
  }

  const handleOpenSpeechBalloon = (val: boolean) => {
    if (isSpeechBalloon) {
      setopenSpeechBalloon(val)
    }
  }

  const handleMouseDown = () => setTypeInput('text')
  const handleMouseUp = () => setTypeInput('password')
  const handleMouseLeave = () => setTypeInput('password')

  const handleClickSeePassword = () => {
    setTypeInput(typeInput == 'text' ? 'password' : 'text')
  }

  const formatCPF = (value: string): string => {
    const cleaned = value.replace(/\D/g, '')
    let formatted = cleaned
    if (cleaned.length == 3) {
      formatted = `${cleaned.slice(0, 3)}.${cleaned.slice(3)}`
    } else if (cleaned.length >= 11) {
      formatted = `${cleaned.slice(0, 3)}.${cleaned.slice(3, 6)}.${cleaned.slice(
        6,
        9
      )}-${cleaned.slice(9, 11)}`
    } else if (cleaned.length >= 6) {
      formatted = `${cleaned.slice(0, 3)}.${cleaned.slice(3, 6)}.${cleaned.slice(6)}`
    }
    return formatted ?? ''
  }

  const formatCNPJ = (value: string): string => {
    const cleaned = value.replace(/\D/g, '')
    let formatted = cleaned
    if (cleaned.length == 2) {
      formatted = `${cleaned.slice(0, 2)}${cleaned.slice(2)}`
    } else if (cleaned.length >= 14) {
      formatted = `${cleaned.slice(0, 8)}/${cleaned.slice(8, 12)}-${cleaned.slice(12, 14)}`
    } else if (cleaned.length >= 12) {
      formatted = `${cleaned.slice(0, 8)}/${cleaned.slice(8, 12)}-${cleaned.slice(12)}`
    } else if (cleaned.length >= 8) {
      formatted = `${cleaned.slice(0, 8)}/${cleaned.slice(8)}`
    }
    // } else if (cleaned.length >= 11) {
    //   formatted = `${cleaned.slice(0, 3)}.${cleaned.slice(3,6)}.${cleaned.slice(6,9)}-${cleaned.slice(9, 11)}`
    // } else if(cleaned.length >= 6){
    //   formatted = `${cleaned.slice(0, 3)}.${cleaned.slice(3,6)}.${cleaned.slice(6)}`
    // }
    return formatted ?? ''
  }

  const formatPhoneNumber = (value: string): string => {
    const cleaned = value.replace(/\D/g, '')
    let formatted = cleaned
    if (cleaned.length > 2 && cleaned.length <= 7) {
      formatted = `(${cleaned.slice(0, 2)}) ${cleaned.slice(2)}`
    } else if (cleaned.length > 7) {
      formatted = `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-${cleaned.slice(7, 11)}`
    }

    return formatted
  }

  return (
    <div
      id={id}
      className={clsx(
        'container d-flex h-full',
        { 'container-material-textfield': variant === 'normal' },
        { 'container-material-textfield-orange': variant === 'orange' },
        { 'container-material-textfield-primary': variant === 'primary' },
        classNameContainer
      )}
    >
      <div className="material-textfield relative">
        {isSpeechBalloon && openSpeechBalloon && (
          <div className="speech-balloon -me-[30px]">{speechBalloonInfos}</div>
        )}
        <input
          name={name}
          placeholder=" "
          type={typeInput}
          className={clsx(
            'text-lg font-semibold w-full h-[48px]',
            classNameInput,
            { 'rounded-lg': variant === 'normal' || roundedBorderLg },
            {
              'rounded-[3.5px]': variant === 'orange' || (variant === 'primary' && !roundedBorderLg)
            }
          )}
          style={{ border: isValidStr(border) ? border : 'auto' }}
          value={valInput}
          onChange={(e) => handleChangeValueInput(e.target.value)}
          onKeyDown={(e) => handleKeyDownConst(e.key)}
          onBlur={() => handleFocusOut()}
          required={required}
        />
        {hasViewPassword && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#848484"
            className="absolute right-0 -mt-[35px] me-4 cursor-pointer"
            // onMouseDown={handleMouseDown}
            // onMouseUp={handleMouseUp}
            // onMouseLeave={handleMouseLeave}
            // onTouchStart={handleMouseDown}
            // onTouchEnd={handleMouseUp}
            onClick={handleClickSeePassword}
            height="24"
            viewBox="0 -960 960 960"
            width="20"
          >
            <path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" />
          </svg>
        )}
        {isValidStr(iconImg) && (
          <img
            src={iconImg}
            onMouseEnter={() => handleOpenSpeechBalloon(true)}
            onMouseLeave={() => handleOpenSpeechBalloon(false)}
            className="absolute -mt-[35px] right-0 me-3"
          />
        )}

        <label
          className={clsx(
            'text-lg font-semibold absolute left-0 top-[50%] origin-left-top',
            classNameLabel
          )}
        >
          {label}
        </label>
      </div>
    </div>
  )
}

export default memo(TextInputField)
