import { useEffect, useState } from 'react'
import BannerNotFound from '../../../assets/Image-not-found-horiz.webp'
import generalApi from '../../../store/reducers/general/generalApi'
import SkeletonLoading from '../../common/SkeletonLoading'
import { IFair } from '../../../interfaces/fair/fair'

interface Props {
  constructionCompanyId?: string | null
  enterpriseId: string
}

export default function CardBannerFair({ constructionCompanyId = null, enterpriseId }: Props) {
  const { data, isLoading, isError, isSuccess } = generalApi.useGetFairByConstructionIdQuery(
    constructionCompanyId ?? ''
  )
  const [fairs, setFairs] = useState<IFair[]>()

  useEffect(() => {
    if (isSuccess) {
      getFairByProductId()
    }
  }, [isSuccess])

  const getFairByProductId = () => {
    let listFair: IFair[] = []
    data?.Data.filter((x) => x.IsActive).forEach((item) => {
      if (item.FairProducts.filter((x) => x.ProductId == enterpriseId).length > 0) {
        listFair.push(item)
      }
    })
    setFairs(listFair)
  }

  return (
    <>
      {isLoading && <SkeletonLoading height={150} />}
      {isSuccess && fairs && fairs.length > 0 && (
        <>
          {window.innerWidth > 767 ? (
            <div className="grid gap-3">
              {fairs.map((item) => {
                return (
                  <a href={'/feiras/' + item.Id}>
                    <img
                      src={item.UrlBannerDesktop ?? BannerNotFound}
                      width="300px"
                      loading="lazy"
                      className="max-w-[1440px] aspect-[1440/330] h-auto md:w-full m-auto banner-patrocinado"
                      alt="Banner Patrocinado"
                    />
                  </a>
                )
              })}
            </div>
          ) : (
            <div className="grid gap-3">
              {fairs.map((item) => {
                return (
                  <a href={'/feiras/' + item.Id}>
                    <img
                      src={item.UrlBannerMobile ?? BannerNotFound}
                      width="300px"
                      loading="lazy"
                      className="max-w-[1440px] aspect-[335/156] h-auto md:w-full m-auto banner-patrocinado"
                      alt="Banner Patrocinado"
                    />
                  </a>
                )
              })}
            </div>
          )}
        </>
      )}
    </>
  )
}
