import { createContext, useContext, useEffect, useState } from 'react'
import {
  AuthContextData,
  AuthProviderProps,
  AuthTokens,
  User,
  UserLoginInput,
  UserSignupInput
} from '../@types/Auth'
import { Cookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { addUser, getByEmailPassword } from '../store/reducers/users'
import accountApi from '../store/reducers/accounts/accountApi'
import { getClaimByClaimName, getIndexClaimByClaimName, isValidStr } from '../common_functions'
import {
  ACCESSTOKEN,
  REFRESHTOKEN,
  USERINFOS,
  USER_FAVORITES_ENTERPRISES
} from '../constants/loginInfos'
import { ResponseLogin } from '../store/reducers/interfaces/ResponseLogin'
import { IFavoriteEnterpriseClaim } from '../interfaces/users/favoriteEnterpriseClaim'
import { USER_CLAIM_FAVORITES_ENTERPRISES } from '../constants/userClaims'
import { IResponseLogin } from '../store/reducers/interfaces/IResponseApi'

export const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export function AuthProvider({ children }: AuthProviderProps) {
  const [tokens, setTokens] = useState<AuthTokens | null>(null)
  const getAuthenticated = () => {
    let access = localStorage.getItem(ACCESSTOKEN)
    let refresh = localStorage.getItem(REFRESHTOKEN)

    return isValidStr(access) && isValidStr(refresh)
  }

  const getUserLogin = () => {
    var storageUserInfos = '{}'
    if(localStorage.getItem(USERINFOS) != undefined && localStorage.getItem(USERINFOS) != null && localStorage.getItem(USERINFOS) != 'undefined'){
      storageUserInfos = localStorage.getItem(USERINFOS) ?? '{}'
    }
    const jsonObj = JSON.parse(storageUserInfos)

    let responseLoginObj: ResponseLogin = Object.assign(
      new ResponseLogin('', '', '', null),
      jsonObj
    )
    return responseLoginObj
  }

  const [authenticated, setAuthenticated] = useState<boolean>(getAuthenticated())
  const [user, setUser] = useState<User | null>(null)
  const [loginUser, setLoginUser] = useState<ResponseLogin | null>(getUserLogin())
  const dispatch = useDispatch()
  const [useAccount, { data, error, isError, isLoading, isSuccess }] = accountApi.useLoginMutation()
  const [isSuccessLogin, setIsSuccessLogin] = useState<boolean>(isSuccess)

  const signup = (credentials: UserSignupInput) => {
    const newUser: UserSignupInput = credentials
    dispatch(addUser(newUser))
  }

  const login = async (credentials: UserLoginInput) => {
    if (!getAuthenticated()) await useAccount(credentials)
  }

  const loginBySocialMedia = (dataParam:IResponseLogin) => {
    if (!getAuthenticated()) {
      const authToken: AuthTokens = {
        access: dataParam.AccessToken ?? '',
        refresh: dataParam.RefreshToken ?? ''
      }
      setTokens(authToken)

      localStorage.setItem(ACCESSTOKEN, dataParam.AccessToken ?? '')
      localStorage.setItem(REFRESHTOKEN, dataParam.RefreshToken ?? '')
    }
    saveInfosUserCookies(dataParam)
    setIsSuccessLogin(true)
  }

  const loginConstrutora = async (credentials: UserLoginInput) => {
    if (!getAuthenticated()) await useAccount(credentials)
  }

  const handleToken = () => {
    const authToken: AuthTokens = {
      access: data?.Data.AccessToken ?? '',
      refresh: data?.Data.RefreshToken ?? ''
    }
    setTokens(authToken)
  }

  const saveCookiesToken = () => {
    localStorage.setItem(ACCESSTOKEN, tokens?.access ?? '')
    localStorage.setItem(REFRESHTOKEN, tokens?.refresh ?? '')
  }

  const handleErrorLogin = () => {
    let boxErrorLoginHTML = document.querySelector('#boxErrorLogin') as HTMLElement
    let contentErrorLoginHTML = document.querySelector('#contentErrorLogin') as HTMLElement
    let boxEmailLoginHTML = document.querySelector('#emailContainerInputLogin') as HTMLElement
    let boxPasswordLoginHTML = document.querySelector('#passwordContainerInputLogin') as HTMLElement
    let errorStr = (error?.toString() ?? '').toLocaleLowerCase().includes('incapaz')
      ? 'E-mail/senha incorreto, tente novamente'
      : error?.toString()
    if (boxErrorLoginHTML != undefined) {
      boxErrorLoginHTML.classList.remove('hidden')
      contentErrorLoginHTML.innerHTML = errorStr ?? ''
    }
    if (boxEmailLoginHTML != undefined) {
      boxEmailLoginHTML.querySelector('label')?.classList.add('label-filled-error')
      boxEmailLoginHTML.querySelector('input')?.classList.add('input-filled-error')
    }
    if (boxPasswordLoginHTML != undefined) {
      boxPasswordLoginHTML.querySelector('label')?.classList.add('label-filled-error')
      boxPasswordLoginHTML.querySelector('input')?.classList.add('input-filled-error')
    }
  }

  const handleLoadingLogin = () => {
    let boxLoadingLoginHTML = document.querySelector('#loadingLogin') as HTMLElement
    if (boxLoadingLoginHTML != undefined) {
      if (isLoading) {
        boxLoadingLoginHTML.classList.toggle('hidden')
        boxLoadingLoginHTML.classList.toggle('inline-block')
      } else {
        boxLoadingLoginHTML.classList.toggle('hidden')
        boxLoadingLoginHTML.classList.toggle('inline-block')
      }
    }
  }

  const getFavoritesEnterprises = (data: IResponseLogin) => {
    var favoritesEnterprises: IFavoriteEnterpriseClaim[] = []
    try {
      // var claimsUser = data.User.Claims[
      //   getIndexClaimByClaimName(USER_CLAIM_FAVORITES_ENTERPRISES)
      // ]
      var claimsUser = getClaimByClaimName(USER_CLAIM_FAVORITES_ENTERPRISES, data.User.Claims)
      favoritesEnterprises = JSON.parse(claimsUser.Value ?? '[]')
    } catch {}
    return favoritesEnterprises
  }

  const saveInfosUserCookies = (dataParam:IResponseLogin|null = null) => {
    var dataAux = dataParam != null ? dataParam : data?.Data
    if (dataAux != undefined) {
      localStorage.setItem(USERINFOS, JSON.stringify(dataAux))
      localStorage.setItem(
        USER_FAVORITES_ENTERPRISES,
        JSON.stringify(getFavoritesEnterprises(dataAux))
      )
      setLoginUser(getUserLogin())
    }
  }

  useEffect(() => {
    handleLoadingLogin()
  }, [isLoading])

  useEffect(() => {
    if (!getAuthenticated()) saveCookiesToken()
  }, [tokens])

  useEffect(() => {
    if (!getAuthenticated() && isSuccess) {
      handleToken()
      saveInfosUserCookies()
    }
    setIsSuccessLogin(isSuccess)
  }, [isSuccess])

  useEffect(() => {
    if (!getAuthenticated() && isError) {
      handleErrorLogin()
    }
  }, [isError])

  useEffect(() => {
    handleAuthenticated()
  }, [tokens])

  const logout = () => {
    localStorage.removeItem(ACCESSTOKEN)
    localStorage.removeItem(REFRESHTOKEN)
    localStorage.removeItem(USERINFOS)
    setAuthenticated(false)
  }

  const handleAuthenticated = () => {
    let accessToken = localStorage.getItem(ACCESSTOKEN)
    let refreshToken = localStorage.getItem(REFRESHTOKEN)
    if (isValidStr(accessToken) && isValidStr(refreshToken)) setAuthenticated(true)
    else setAuthenticated(false)
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        authenticated,
        tokens,
        setTokens,
        loginBySocialMedia,
        login,
        loginConstrutora,
        isSuccessLogin,
        loginUser,
        logout,
        signup,
        getAuthenticated
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
