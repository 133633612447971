import React from 'react'
import Img from '../../assets/localizacao-ex.webp'
import Arrow from '../../assets/icons/icon-arrow-up.svg'
import { Suspense, useEffect, useState } from 'react'
import clsx from 'clsx'
import { Button } from '../common'
import { isValidStr } from '../../common_functions'
import SkeletonLoading from '../common/SkeletonLoading'
import enterpriseApi from '../../store/reducers/enterprise/enterpriseApi'

interface EnterpriseDetailCard {
  EnterpriseId: string
}

export default function Tour360({ EnterpriseId }: EnterpriseDetailCard) {
  const [hiddenContent, setHiddenContent] = useState(true)
  const {data, isError, isSuccess, isLoading} = enterpriseApi.useGetEnterprisePresentationMediaByIdQuery(EnterpriseId??"")
  const categoryIdTourVirtual = '18db3ade-c66a-4b7d-9806-5b0f58871682'

  const bgStyles = {
    backgroundImage: `url(${Img})`,
    aspectRatio: '15/11'
  }

  return (
    <>
      {isLoading && <SkeletonLoading height={150}/>}
      {isSuccess && isValidStr(data?.Data.TourVirtual) ? (
        <>
          <section className="p-3 pl-0" id="navInternEnterpriseTour360">
            <h3 className="font-bold text-xl md:text-2xl my-5 text-[#4D4D4D]">
              Tour 360°
            </h3>
            <div className={clsx()}>
              <div className="bg-[#f2f2f2] rounded-lg">
                <div className="md:grid md:grid-cols-2 p-4 px-6">
                  <div className="m-auto">
                    <h5 className="text-base font-[500] text-center lg:text-start text-gray-800">
                      Quer ver o imóvel por dentro sem sair de casa? Faça um Tour 360°!
                    </h5>
                  </div>
                  <div className="text-center mt-5 md:mt-auto">
                    <a target='_blank' href={`${data?.Data.TourVirtual?.includes('http') ? '' : 'https://'}${data?.Data.TourVirtual}`}>
                      <Button
                        id="startTour360"
                        variant="primary"
                        className="font-bold text-base m-auto rounded-lg lg:rounded-none w-full lg:w-[80%]"
                      >
                        INICIAR TOUR 360°
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <hr className="z-[-1] relative opacity-10 mt-4" />
        </>
      ) : (
        <></>
      )}
    </>
  )
}
