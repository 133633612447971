import React from 'react'
import Img from '../../assets/localizacao-ex.webp'
import Arrow from '../../assets/icons/icon-arrow-up.svg'
import { Suspense, useEffect, useState } from 'react'
import clsx from 'clsx'
import { isValidStr } from '../../common_functions'
// import { format } from 'date-fns';
interface Props {
  lastPublish: string
}

export default function LastPublish({ lastPublish }: Props) {
  const formattedDate = (): string => {
    const date = new Date(lastPublish)
    return `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}/${
      date.getMonth() + 1 < 10 ? '0' : ''
    }${date.getMonth() + 1}/${date.getFullYear()}`
  }

  return (
    <>
      {isValidStr(lastPublish) && (
        <>
          <section className="p-3 pl-0" id="navInternEnterpriseFonte">
            <div className={clsx()}>
              <h3 className="font-bold text-xl md:text-2xl my-5 text-[#4D4D4D]">
                Data da última atualização*:
              </h3>
              <h5 className="text-xl md:text-2xl text-gray-800">
                <p className="text-black opacity-70 font-semibold">{formattedDate()}</p>
              </h5>
              <p className='text-caption opacity-50 mt-5 font-semibold'>
                *O glemO é um hub imobiliário digital, que funciona como um intermediador de compra
                e venda de imóveis novos. Caso ocorra qualquer alteração nos dados do produto, tais
                como preço, disponibilidade ou especificações, nós não nos responsabilizamos por
                tais mudanças do parceiro.   Recomendamos que todos os detalhes sejam confirmados
                diretamente com a empresa responsável, antes de efetivar qualquer transação. 
              </p>
            </div>
          </section>
          <hr className=" mt-4 z-[-1] relative opacity-10" />
        </>
      )}
    </>
  )
}
