import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { District } from '../../../interfaces/district'
import { DataRTKReturn, DataRTKReturnList } from '../../../interfaces/dataRTKReturn'
import { IProductSearchedViewed, IProductSearchedViewedUpdt } from '../../../interfaces/productSearchedViewed'
import { IBuilderSearch } from '../../../interfaces/builderSearch'
import { IFair } from '../../../interfaces/fair/fair'
import { IPromotion } from '../../../interfaces/promotion'
import { IAddressZipcode } from '../../../interfaces/addressZipcode'
import { IContactNewsletter } from '../../../interfaces/contact'
import { ICardBlog, ICommentBlog } from '../../../interfaces/blog'

const generalApi = createApi({
  reducerPath: 'generalApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}` }),
  tagTypes: ['generals'],
  endpoints: (builder) => ({
    addProductSearchedViewed: builder.mutation<DataRTKReturn<string>, IProductSearchedViewed>({
      query: (content) => ({
        url: `/product-searched-viewed/add`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['generals']
    }),
    getProductSearchedViewed: builder.query<DataRTKReturn<string>, string>({
      query: (id) => `/product-searched-viewed/get-by-id/${id}`
    }),
    getProductsByBuilderId: builder.query<DataRTKReturnList<IBuilderSearch>, string>({
      query: (id) => `/glemo/construction-company-by-argument?argument=${id}`
    }),
    updtProductSearchedViewed: builder.mutation<string, IProductSearchedViewedUpdt>({
      query: (content) => ({
        url: `/product-searched-viewed/update`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['generals']
    }),
    createContactNewsletter: builder.mutation<string, IContactNewsletter>({
      query: (content) => ({
        url: `/news/create-contact`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['generals']
    }),
    getPromotionsAll: builder.mutation<DataRTKReturnList<IPromotion>, undefined>({
      query: () => ({
        url: `/glemo/promotions/get-all`,
        body: JSON.stringify({
          title: null,
          activated: null
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['generals']
    }),
    createCommentBlog: builder.mutation<string, ICommentBlog>({
      query: (obj) => ({
        url: `/glemo/create-comment-blog`,
        body: JSON.stringify(obj),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['generals']
    }),
    createCommentResponseBlog: builder.mutation<string, ICommentBlog>({
      query: (obj) => ({
        url: `/glemo/create-response-comment-blog`,
        body: JSON.stringify(obj),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['generals']
    }),
    getFairById: builder.query<DataRTKReturn<IFair>, string>({
      query: (id) => `/glemo/get-fair-by-id?fairId=${id}`,
    }),
    getAddressByZipCode: builder.query<DataRTKReturn<IAddressZipcode>, string>({
      query: (cep) => `/addresses/get-full-by-zip-code/${cep}`,
    }),
    getFairByConstructionId: builder.query<DataRTKReturnList<IFair>, string>({
      query: (id) => `/get-all/fair/by-construction-company-id?constructionCompanyId=${id}`,
    }),
    getAllCardsBlog: builder.query<DataRTKReturnList<ICardBlog>, null>({
      query: () => `/glemo/get-all-blog`,
    }),
    getBlogBySlug: builder.query<DataRTKReturn<ICardBlog>, string>({
      query: (slug) => `/glemo/get-blog-by-slug?slug=${slug}`,
    }),
  })
})

export default generalApi
