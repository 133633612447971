import React from 'react'
import Img from '../../assets/localizacao-ex.webp'
import Arrow from '../../assets/icons/icon-arrow-up.svg'
import { Suspense, useEffect, useState } from 'react'
import clsx from 'clsx'
import { isValidList, strToSlug } from '../../common_functions'
import { API_URL } from '../../constants/baseUrlApiStatic'
import SkeletonLoading from '../common/SkeletonLoading'
import enterpriseApi from '../../store/reducers/enterprise/enterpriseApi'
import { ENUM_PHOTO_PLANT } from '../../constants/enterprisePresentationMediaTypesEnums'
import NotFoundImg from '../../assets/Image-not-found.webp'
import { ModalPresentation } from '../Modais/ModalPresentation'

interface EnterpriseDetailCard {
  EntepriseId: string
}

export default function Planta({ EntepriseId }: EnterpriseDetailCard) {
  const [hiddenContent, setHiddenContent] = useState(true)
  const { data, isSuccess, isError, isLoading } = enterpriseApi.useGetEnterpriseMediasByIdQuery(
    EntepriseId ?? ''
  )
  const [bannerUrls, setBannerUrls] = useState<{ [key: string]: string }>({})
  const [selectedImgModal, setSelectedImgModal] = useState('')
  const [showModal, setShowModal] = useState(false)

  function checkImagemBanner(imgUrl: string): Promise<string> {
    return new Promise((resolve) => {
      const img = new Image()
      img.src = imgUrl
      img.onload = () => {
        resolve(imgUrl)
      }
      img.onerror = () => {
        resolve(NotFoundImg) // Utilize a URL ou caminho da imagem NotFoundImg
      }
    })
  }

  useEffect(() => {
    if (isSuccess && data?.Data != undefined) {
      const fetchBannerUrls = async () => {
        const tempUrls: { [key: string]: string } = {}
        for (const item of data?.Data) {
          const url: string = await checkImagemBanner(item.FileUrl)
          tempUrls[item.FileUrl] = url
        }
        setBannerUrls(tempUrls)
      }

      fetchBannerUrls()
    }
  }, [isSuccess])

  const handleShowImg = (img:string) => {
    setSelectedImgModal(img)
    setShowModal(true)
  }

  return (
    <>
      {isLoading && <SkeletonLoading height={300} />}
      {isSuccess &&
      data?.Data.filter((x) => x.Category.Enumerator == ENUM_PHOTO_PLANT).length > 0 ? (
        <>
          <section className="p-3 pl-0" id="navInternEnterprisePlant">
            <h3 className="font-bold text-xl md:text-2xl my-5 text-[#4D4D4D]">Planta</h3>
            <div className={clsx('lg:grid lg:grid-cols-2')}>
              {data?.Data.filter((x) => x.Category.Enumerator == ENUM_PHOTO_PLANT).map((item) => {
                return (
                  <Suspense fallback={<SkeletonLoading height={100} />}>
                    <div
                      key={`${strToSlug(item?.Title ?? '')}`}
                      onClick={() => handleShowImg(bannerUrls[item.FileUrl])}
                      className="bg-cover bg-no-repeat w-full aspect-[15/11] mt-4"
                      style={{
                        backgroundImage: `url('${bannerUrls[item.FileUrl]}')`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        height: '350px'
                      }}
                    ></div>
                  </Suspense>
                )
              })}
            </div>
          </section>
          <hr className=" z-[-1] relative opacity-10" />
          <ModalPresentation imgUrl={selectedImgModal} showModal={showModal} closeModal={() => setShowModal(false)}/>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
