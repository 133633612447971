import React, { useLayoutEffect } from 'react'
import { Suspense, lazy, useEffect, useState } from 'react'
import { AuthProvider, useAuth } from './hooks/useAuth'
import { Cookies } from 'react-cookie'
import Logo from './assets/glemo-logo.webp'
import { DATE_ENTRY, DEVICE_ID, LOCATIONS } from './constants/storageNames'
import { isMobile, isValidStr } from './common_functions'
const AppRouter = lazy(() => import('./routes'))
import HeaderMobile from './components/HeaderMobile'
import SectionBestPropertiesFullTextBasic from './components/Sections/SectionBestPropertiesFullText/indexBasic'
import CarouselConstructionCompanies from './components/Carousels/CarouselConstructionCompanies'
import { Header } from './components'
import SkeletonLoading from './components/common/SkeletonLoading'
import UserLoginIcon from './assets/icons/blue-user.svg'
import { InternEnterpriseInit } from './pages/Empreendimentos/InternEnterpriseInit'
import { Button, TextInput } from './components/common'
import { InternEnterprise } from './pages'

export default function App() {
  const location = window.location
  const cookies = new Cookies()
  const [allowLocals, setAllowLocals] = useState(false)
  const [loadingComponents, setLoadingComponents] = useState(
    location.pathname !== '/' &&
    (!location.pathname.includes('/empreendimentos/') || !isMobile())
  );

  const isValidPathname = () => {
    return location.pathname != '/entrar' && location.pathname != '/registrar'
  }

  const handleLoad = () => {
    // alert('Invoked handle load')
    if (!loadingComponents) {
      const head = document.head
      const body = document.body

      const script1 = document.createElement('script')
      script1.defer = true
      script1.innerHTML = `window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }`
      head.appendChild(script1)

      const script2 = document.createElement('script')
      script2.defer = true
      script2.innerHTML = `(function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start': new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-WQ39T96');`
      head.appendChild(script2)

      const linkTiny = document.createElement('link')
      // <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.3/tiny-slider.css" />
      linkTiny.rel = 'stylesheet'
      linkTiny.href = 'https://cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.3/tiny-slider.css'
      head.appendChild(linkTiny)

      const script3 = document.createElement('script')
      script3.type = 'application/ld+json'
      script3.defer = true
      script3.innerHTML = `{
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "glemO",
      "alternateName": "glemo Imóveis",
      "url": "https://www.glemo.com.br",
      "logo": "https://www.glemo.com.br/assets/glemo-logo-2-c14faa1a.png",
      "sameAs": [
        "https://www.instagram.com/portalglemo/",
        "https://www.facebook.com/portalglemo"
      ]
    }`

      head.appendChild(script3)

      const adopt = document.createElement('meta')
      adopt.name = 'adopt-website-id'
      adopt.content = '2c1b73d6-dc7b-4ab5-845a-9797ac244096'

      head.appendChild(adopt)

      const scriptAdopt = document.createElement('script')
      scriptAdopt.defer = true
      scriptAdopt.src =
        '//tag.goadopt.io/injector.js?website_code=2c1b73d6-dc7b-4ab5-845a-9797ac244096'
      scriptAdopt.className = 'adopt-injector'

      head.appendChild(scriptAdopt)

      const linkfontsgoogleapi = document.createElement('link')
      linkfontsgoogleapi.rel = 'preconnect'
      linkfontsgoogleapi.href = 'https://fonts.googleapis.com'
      const linkfontsgtatic = document.createElement('link')
      linkfontsgtatic.rel = 'preconnect'
      linkfontsgtatic.href = 'https://fonts.gstatic.com'
      linkfontsgtatic.crossOrigin = 'true'

      head.appendChild(linkfontsgtatic)

      const script5 = document.createElement('script')
      script5.innerHTML = `document.addEventListener('df-messenger-opened', function (event) {
      let adPolicy = document.querySelector('#adopt-controller-button')
      if (adPolicy != undefined && window.screen.width < 769) {
        adPolicy.style.setProperty('display', 'none', 'important')
      }
    })
    `
      body.appendChild(script5)

      setLoadingComponents(true)
      window.removeEventListener('touchstart', handleLoad)
      window.removeEventListener('touchmove', handleLoad)
      window.removeEventListener('scroll', handleLoad)
      window.removeEventListener('click', handleLoad)
    }
  }

  useEffect(() => {
    if (isMobile()) {
      window.addEventListener('touchstart', handleLoad)
      window.addEventListener('touchmove', handleLoad)
      window.addEventListener('scroll', handleLoad)
      window.addEventListener('click', handleLoad)

      return () => {
        window.removeEventListener('touchstart', handleLoad)
        window.removeEventListener('touchmove', handleLoad)
        window.removeEventListener('scroll', handleLoad)
        window.removeEventListener('click', handleLoad)
      }
    } else {
      window.addEventListener('load', handleLoad)
      return () => {
        window.removeEventListener('load', handleLoad)
      }
    }
  }, [])

  // useEffect(() => {
  //   window.addEventListener('load', handleLoad)
  //   return () => {
  //     window.removeEventListener('load', handleLoad)
  //   }
  // }, [])

  // useEffect(() => {
  //   if (isMobile()) {
  //     const waitForImagesToLoad = (): Promise<void> => {
  //       const images: HTMLImageElement[] = Array.from(document.images) as HTMLImageElement[]
  //       if (images.length === 0) {
  //         return Promise.resolve()
  //       }
  //       const promises = images.map((img) => {
  //         if (img.complete) {
  //           return Promise.resolve()
  //         }
  //         return new Promise<void>((resolve) => {
  //           img.onload = img.onerror = () => resolve()
  //         })
  //       })
  //       return Promise.all(promises).then(() => {})
  //     }

  //     waitForImagesToLoad().then(() => {
  //       // alert('Página completamente carregada com todas as imagens.');
  //       setTimeout(() => {
  //         if (!loadingComponents) {
  //           handleLoad()
  //         }
  //       }, 5000)
  //     })
  //   }
  // }, [loadingComponents])

  useEffect(() => {
    if (isValidPathname()) {
      setAllowLocals(true)
    }
    addDateEntry()
    addDeviceId()
  }, [location.pathname])

  const addDateEntry = () => {
    let dateEntryAux = sessionStorage.getItem(DATE_ENTRY)
    if (!isValidStr(dateEntryAux)) {
      sessionStorage.setItem(DATE_ENTRY, new Date().toISOString())
    }
  }

  const randomNumberInRange = () => {
    return Math.floor(Math.random() * (10000 - 0 + 1)) + 0
  }

  const addDeviceId = () => {
    let deviceId = cookies.get(DEVICE_ID)
    if (deviceId == undefined || deviceId == null || deviceId == 0) {
      cookies.set(DEVICE_ID, randomNumberInRange())
    }
  }

  return (
    <div className={`h-full app-root`}>
      <AuthProvider>
        <Suspense fallback={<div id="loadingBox"></div>}>
          {/* <AppRouter /> */}
          {loadingComponents ? (
            <AppRouter />
          ) : (
            <>
              {location.pathname == '/' ? (
                <>
                  <header className="header-mobile px-4 bg-white top-0 pt-5 pb-2 fixed z-[99] md:hidden w-[100vw]">
                    <div id="mobileContainerSearch">
                      <div className="grid grid-cols-3">
                        <div className="m-auto ml-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            fill="currentColor"
                            className="text-glemo-blue"
                            viewBox="0 0 256 256"
                          >
                            <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM40,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z"></path>
                          </svg>
                        </div>
                        <div className="m-auto">
                          <a href={'/'}>
                            <Suspense fallback={<SkeletonLoading />}>
                              <img alt="Glemo Logo" src={Logo} className="w-full aspect-[191/69]" />
                            </Suspense>
                          </a>
                        </div>
                        <div className="m-auto mr-0">
                          <a href="/entrar">
                            <span className="text-blue-700 text-lg font-semibold tracking-wider">
                              <img
                                src={UserLoginIcon}
                                width={20}
                                alt="User Icon"
                                className="m-auto"
                              />
                              <div className="-mt-[6px] text-[14px]">Entrar</div>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </header>
                  <div className="hidden lg:block bg-white text-center py-3">
                    <a href={'/'} className="w-fit">
                      <img alt="Glemo Logo" src={Logo} className="w-[100px] m-auto" />
                    </a>
                  </div>
                  {/* <Header/> */}
                  <SectionBestPropertiesFullTextBasic />
                  <div id="loadingBox" className="hidden lg:block"></div>
                  {/* <CarouselConstructionCompanies/> */}
                  {/* <div className="container-responsive-view gap-4 mt-10">
                    <h2 className="text-4xl lg:text-6xl mb-5 text-black font-bold">
                      <>
                        <span className="overline decoration-[#F15C00]">Baseados </span>
                        na sua localização
                      </>
                    </h2>
                    <p className="text-xl">
                      Descubra as nossas principais escolhas próximas a você.
                    </p>
                  </div>
                  <div id="loadingBox"></div> */}
                </>
              ) : (
                <>
                  {location.pathname.includes('/empreendimentos') ? (
                    <>
                      <div className="mt-[150px]">
                        <InternEnterprise isLoadingFull={false} />
                      </div>
                    </>
                  ) : (
                    <>
                      <div id="loadingBox"></div>
                      <div className="mt-4 text-center">
                        <img alt="Glemo Logo" src={Logo} className="w-[150px] m-auto" />
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Suspense>
      </AuthProvider>
    </div>
  )
}
