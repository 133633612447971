import React from 'react'
import Img from '../../assets/localizacao-ex.webp'
import Arrow from '../../assets/icons/icon-arrow-up.svg'
import { Suspense, useEffect, useState } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import SkeletonLoading from '../common/SkeletonLoading'
import { isValidStr } from '../../common_functions'

interface Props {
  fonteUrl: string
}

export default function Fonte({ fonteUrl }: Props) {
  const [hiddenContent, setHiddenContent] = useState(true)

  const bgStyles = {
    backgroundImage: `url(${Img})`,
    aspectRatio: '15/11'
  }

  return (
    <>
      {isValidStr(fonteUrl) && (
        <>
          <section className="p-3 pl-0 cursor-pointer" id="navInternEnterpriseFonte">
            <h3 className="font-bold text-xl md:text-2xl my-5 text-[#4D4D4D]">
              Fonte
            </h3>
            <div className={clsx()}>
              <h5 className="text-xl text-gray-800">
                Produto:{' '}
                <a href={fonteUrl} className="text-black opacity-70 font-bold" target="_blank">
                  {fonteUrl}
                </a>
              </h5>
            </div>
          </section>
          <hr className=" mt-4 z-[-1] relative opacity-10" />
        </>
      )}
    </>
  )
}
