import React from 'react'
import { Suspense, lazy, useCallback, useEffect, useState } from 'react'
import LogoMrv from '../../../assets/mrv-logo.webp'
import { Enterprise2 } from '../../../interfaces/enterprise/enterprise_2'
import enterpriseApi from '../../../store/reducers/enterprise/enterpriseApi'
import { useAuth } from '../../../hooks/useAuth'
import { USER_CLAIM_FAVORITES_ENTERPRISES } from '../../../constants/userClaims'
import { IFavoriteEnterpriseClaim } from '../../../interfaces/users/favoriteEnterpriseClaim'
import { ModalError } from '../../Modais/ModalError'
import { useNavigate } from 'react-router-dom'
import { USER_FAVORITES_ENTERPRISES } from '../../../constants/loginInfos'
import generalApi from '../../../store/reducers/general/generalApi'
import { IProductSearchedViewed } from '../../../interfaces/productSearchedViewed'
import { FULL_TEXT, ID_PRODUCT_SEARCHED_VIEWED } from '../../../constants/storageNames'
import { IProductConditions } from '../../../interfaces/fair/fair'
import { saveCardsStorage } from '../../../common_functions'

const CardEnterpriseHeader = lazy(() => import('./CardEnterpriseHeader'))
const CardEnterpriseStatusActions = lazy(() => import('./CardEnterpriseStatusActions'))
const CardEnterpriseInfos = lazy(() => import('./CardEnterpriseInfos'))
const CardEnterpriseCharacters = lazy(() => import('./CardEnterpriseCharacters'))
const CardEnterpriseAdvertiserPrice = lazy(() => import('./CardEnterpriseAdvertiserPrice'))
const CardEnterpriseFooter = lazy(() => import('./CardEnterpriseFooter'))
const SkeletonLoading = lazy(() => import('../../common/SkeletonLoading'))

interface Props {
  enterprise: Enterprise2
  isFavoriteDefault?: boolean | undefined
  conditions?: IProductConditions[]
  removeFavorite?: (elementObj: IFavoriteEnterpriseClaim) => void | undefined
  classNameCard?: string
  isPromotion?: boolean
}
export default function CardEnterprise({
  enterprise,
  isFavoriteDefault,
  conditions,
  removeFavorite,
  isPromotion = false,
  classNameCard
}: Props) {
  const navigation = useNavigate()
  const auth = useAuth()
  const [showModalError, setShowModalError] = useState(false)
  const [favoriteEnterprises, setfavoriteEnterprises] = useState<IFavoriteEnterpriseClaim[]>(
    auth.loginUser != null && auth.loginUser[0] != null && auth.loginUser[0].User != null
      ? JSON.parse(localStorage.getItem(USER_FAVORITES_ENTERPRISES) ?? '[]')
      : []
  )
  const [
    useAddProductSearchedViewed,
    {
      data: dataProductSearched,
      isLoading: isLoadingProductSearched,
      isError: isErrorProductSearched,
      isSuccess: isSuccessProductSearched
    }
  ] = generalApi.useAddProductSearchedViewedMutation()

  const addProductSearchedViewed = async () => {
    const argumentSearch = sessionStorage.getItem(FULL_TEXT)
    const content: IProductSearchedViewed = {
      enterpriseId: enterprise.Id,
      productName: enterprise.Title,
      searchArgument: argumentSearch ?? ''
    }
    await useAddProductSearchedViewed(content)
  }

  const handleCloseModal = useCallback(() => {
    setShowModalError(false)
  }, [showModalError])

  useEffect(() => {
    if (typeof favoriteEnterprises === 'string') {
      setfavoriteEnterprises(JSON.parse(favoriteEnterprises))
    }
  }, [favoriteEnterprises])

  const addEnterpriseViewed = async () => {
    if (location.pathname != '/busca/') {
      saveCardsStorage(enterprise.Title, enterprise.BannerUrl, enterprise.Status, enterprise.Location, enterprise.Slug)
      navigation(`/empreendimentos/${enterprise.Slug}`)
    } else {
      await addProductSearchedViewed()
    }
  }

  useEffect(() => {
    if (isSuccessProductSearched) {
      sessionStorage.setItem(ID_PRODUCT_SEARCHED_VIEWED, dataProductSearched?.Data ?? '')
      saveCardsStorage(enterprise.Title, enterprise.BannerUrl, enterprise.Status, enterprise.Location, enterprise.Slug)
      navigation(`/empreendimentos/${enterprise.Slug}`)
    }
  }, [isSuccessProductSearched])

  return (
    <div
      data-enterprise={`${enterprise.Id}`}
      className={`card-enterprise lg:grid rounded-b-3xl lg:h-full w-full max-w-[453px] bg-white rounded-t-lg full-height m-auto ${classNameCard}`}
      style={{ alignContent: 'space-between', boxShadow: '0px -1px 5px -4px black' }}
    >
      <div>
        <Suspense fallback={<SkeletonLoading />}>
          <CardEnterpriseHeader isPromotion={isPromotion} conditions={conditions} addEnterpriseViewed={addEnterpriseViewed} enterprise={enterprise} />
        </Suspense>
        <div className='pt-3 px-3'>
          <Suspense fallback={<SkeletonLoading />}>
            <CardEnterpriseStatusActions
              enterprise={enterprise}
              isFavoriteDefault={isFavoriteDefault}
              removeFavorite={removeFavorite}
            />
          </Suspense>
          <Suspense fallback={<SkeletonLoading />}>
            <div onClick={addEnterpriseViewed} className="cursor-pointer">
              <CardEnterpriseInfos Title={enterprise.Title} Address={enterprise.Location} />
            </div>
          </Suspense>
          <Suspense fallback={<SkeletonLoading />}>
            <div onClick={addEnterpriseViewed} className="cursor-pointer">
              <CardEnterpriseCharacters enterprise={enterprise} />
            </div>
          </Suspense>
          <Suspense fallback={<SkeletonLoading />}>
            <CardEnterpriseAdvertiserPrice enterprise={enterprise} />
          </Suspense>
        </div>
      </div>
      <div>
        <div className="rounded-b-[8px] py-3 px-3 relative -mb-2 bg-white shadow-md z-0">



        </div>
        <Suspense fallback={<SkeletonLoading />}>
          <CardEnterpriseFooter
            isLoading={isLoadingProductSearched}
            addEnterpriseViewed={addEnterpriseViewed}
          />
        </Suspense>
      </div>

      <ModalError
        showModal={showModalError}
        closeModal={handleCloseModal}
        title="Erro!"
        description="Houve um erro ao adicionar aos favoritos, tente logar novamente."
      />
    </div>
  )
}
