import React, { useEffect, useState } from "react"
import SectionDetails from "../InternEnterprise/SectionDetails"
import SectionMedia from "../InternEnterprise/SectionMedia"
import SectionKnowUnits from "../InternEnterprise/SectionKnowUnits"
import { EnterpriseData } from "../../interfaces/enterprise/enterpriseData"
import enterpriseApi from "../../store/reducers/enterprise/enterpriseApi"
import SkeletonLoading from "../common/SkeletonLoading"


interface Props {
  enterprise: EnterpriseData
}

export default function NavInternEnterprise({enterprise}:Props) {
  const [selectedSection, setSelectedSection] = useState(0)
  const { data, isLoading, isError, isSuccess } = enterpriseApi.useGetUnitsQuery(enterprise.Id)
  // let navsSection: { [id: number]: string } = { 0: 'Detalhes', 1: 'Galeria' }
  const [navsSection, setNavsSection] = useState(["Detalhes", "Galeria"])
  // let navsSection:string[] = ["Detalhes", "Galeria"]

  useEffect(() => {
    if(isSuccess){
      var list = navsSection
      list.push("Unidades")
      setNavsSection(list)
    }
  }, [isSuccess])

  useEffect(() => {
  }, [navsSection])

  return (
    <>
      <div className="flex border-b-1 border-gray-100">
        {navsSection.map((item, index) => {
          return (
            <button
              className={
                'text-caption mt-2 lg:mt-auto lg:text-2xl font-bold pb-2 w-[150px] max-w-full ' +
                (selectedSection == index
                  ? 'text-[#F15C00] border-b-2'
                  : 'text-[#000000A6] ')
              }
              onClick={() => setSelectedSection(index)}
            >
              {item}
            </button>
          )
        })}
      </div>
      {selectedSection == 0 && <SectionDetails enterprise={enterprise} />}
      {selectedSection == 1 && <SectionMedia enterprise={enterprise} />}
      {selectedSection == 2 && isSuccess && data != undefined && <SectionKnowUnits enterprise={enterprise} units={data?.Data} />}
    </>
  )
}
