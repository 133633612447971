import React from 'react'
import enterpriseApi from '../../../store/reducers/enterprise/enterpriseApi'
import { Suspense, lazy, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Logo from '../../../assets/glemo-logo.webp'
import UserLoginIcon from '../../../assets/icons/blue-user.svg'
import SeparatorMicrophoneGray from '../../../assets/icons/separator-microphone-gray.svg'
import IconShare from '../../../assets/icons/icon-blue-share.svg'

import { formatMoneyReal, isMobile } from '../../../common_functions'
import NewCarouselEnterprise from '../../../components/Carousels/NewCarouselEnterprise'
import CarouselEnterpriseModalMobile from '../../../components/Carousels/CarouselEnterpriseModalMobile'
import CarouselEnterpriseModalMobileInit from '../../../components/Carousels/CarouselEnterpriseModalMobile/indexInit'
import { Button, TextInput } from '../../../components/common'
import Voice2Text from '../../../components/Voice2Text'
import { EnterpriseData } from '../../../interfaces/enterprise/enterpriseData'
import BaseInfos from '../BaseInfos'

const Header = lazy(() => import('../../../components/InternEnterprise/Header'))
const CardEnterpriseInternInfosPrice = lazy(
  () => import('../../../components/Cards/CardEnterpriseInternInfosPrice')
)
const SkeletonLoading = lazy(() => import('../../../components/common/SkeletonLoading'))

interface Props {
  dataEnterprise: EnterpriseData | undefined
  isLoadingEnterpriseData: boolean
  isSuccessEnterpriseData: boolean
}
export function InternEnterpriseInit({
  dataEnterprise,
  isLoadingEnterpriseData,
  isSuccessEnterpriseData
}: Props) {
  return (
    <>
      <header className="header-mobile px-4 bg-white top-0 pt-5 pb-2 fixed z-[99] md:hidden w-[100vw]">
        <div id="mobileContainerSearch">
          <div className="grid grid-cols-3">
            <div className="m-auto ml-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                fill="currentColor"
                className="text-glemo-blue"
                viewBox="0 0 256 256"
              >
                <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM40,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z"></path>
              </svg>
            </div>
            <div className="m-auto">
              <a href={'/'}>
                <Suspense fallback={<SkeletonLoading />}>
                  <img alt="Glemo Logo" src={Logo} className="w-full aspect-[191/69]" />
                </Suspense>
              </a>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <TextInput.Root className={isMobile() ? 'border-none' : ''}>
            {!isMobile() && (
              <TextInput.Icon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  width="32"
                  height="32"
                  viewBox="0 0 256 256"
                >
                  <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
                </svg>
              </TextInput.Icon>
            )}
            <TextInput.Input
              id="enderecoBuscaEmpreendimento"
              className={
                'text-blue-700 md:text-sm xl:text-2xl enderecoBuscaEmpreendimento ' + isMobile()
                  ? 'w-[inherit]'
                  : ''
              }
              placeholder="Busque seu imóvel aqui"
            />
            <TextInput.Icon className="min-w-max">
              <img src={SeparatorMicrophoneGray} className="w-1" />
            </TextInput.Icon>
            <TextInput.Icon>
              <Voice2Text />
            </TextInput.Icon>
          </TextInput.Root>
        </div>
        <Button
          variant="secondary"
          aria-label="Filtrar"
          id="openSectionFilter"
          value="Filtrar"
          className={`order-2 w-full mt-3`}
        >
          <span>Filtros</span>
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.78573 9.02344H0.928589M18.0714 14.7377L15.2143 14.7377M18.0714 3.30915L10.6429 3.30916M8.35716 3.30915L0.92859 3.30915M6.07145 9.02344L18.0714 9.02344M12.9286 14.7377H0.92859M12.9286 12.452V17.0234L15.2143 17.0234V12.452L12.9286 12.452ZM3.78573 6.73772V11.3092H6.07145V6.73772H3.78573ZM8.35716 1.02344V5.59487H10.6429V1.02344L8.35716 1.02344Z"
              stroke="#0059FF"
            />
          </svg>
        </Button>
      </header>
      <div className="lg:pt-5 lg:-mt-[150px]">
        {isLoadingEnterpriseData && (
          <>
            <SkeletonLoading height={400} />
          </>
        )}
        {isSuccessEnterpriseData && dataEnterprise && (
          <>
            <Suspense fallback={<SkeletonLoading />}>
              <CarouselEnterpriseModalMobileInit EnterpriseId={dataEnterprise?.Id ?? ''} />
            </Suspense>
          </>
        )}
        <>
          <div className="flex px-5">
            <div className="w-[244px] max-w-full min-w-fit">
              <SkeletonLoading height={30} />
            </div>
            <div className="group-actions gap-2 w-[50px] lg:float-right m-auto lg:mr-0">
              <div className="w-[70px] m-auto me-0">
                <a href="#">
                  <div className="bg-[#F0F3F6] rounded h-full text-center pt-1 cursor-pointer">
                    <Suspense fallback={<SkeletonLoading />}>
                      <img
                        alt="Icon Share"
                        src={IconShare}
                        className="inline align-text-top"
                        style={{ verticalAlign: 'top' }}
                      />
                    </Suspense>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <section className="container-responsive-view gap-4 lg:mt-10">
            <div className="lg:grid lg:grid-cols-3">
              <div className="lg:col-span-3">
                <div className="lg:grid lg:grid-cols-3">
                  {/* INFORMACOES  */}
                  <div className="lg:pl-10 order-2">
                    <div className="card-enterprise-intern-infos-prices lg:hidden fixed bottom-0 shadow-lg w-full left-0 z-[10]">
                      <div
                        className="grid grid-cols-2 mx-5 shadow-lg py-5 px-3 bg-white m-auto"
                        style={{ boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.2)' }}
                      >
                        {/* {isPromotion && <img src={TagPromotion} width={40} className='absolute right-10 top-0 -mt-[3px]'/>} */}
                        <div className="m-auto ms-0">
                          <h6 className="text-lg font-semibold text-[#333]">
                            {dataEnterprise?.PriceMinimumValue === 0 ? 'Valor' : `A partir de`}
                          </h6>
                          <h6 className="text-[#4D4D4D] text-xl font-semibold">
                            {!isSuccessEnterpriseData ||
                            !dataEnterprise ||
                            dataEnterprise?.PriceMinimumValue === 0
                              ? 'Sob Consulta'
                              : `${formatMoneyReal(dataEnterprise?.PriceMinimumValue ?? 0)}`}
                          </h6>
                        </div>
                        <div className="m-auto me-0">
                          <Button
                            variant="orange"
                            className="font-bold w-full btn-submit-modal-form-contact"
                            paddingXClass="px-2"
                          >
                            <small>TENHO INTERESSE</small>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isSuccessEnterpriseData && dataEnterprise ? (
                    <>
                      <div
                        className="lg:col-span-2 lg:pr-10 mt-3 lg:mt-auto"
                        style={{ overflowWrap: 'break-word' }}
                      >
                        <div>
                          <h2 className="text-4xl lg:text-6xl leading-[48px] font-bold mt-2 lg:mt-5">
                            {dataEnterprise?.Title ?? ''}
                          </h2>
                          <p
                            className="text-[#1f1e1e] opacity-80 text-xl lg:text-2xl mb-5 lg:my-5 -z-[1]"
                            style={{ position: 'relative' }}
                          >
                            <span className="lg:hidden">
                              {/* <MapPin weight="fill" className="inline-block" /> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                className="inline-block"
                              >
                                <path
                                  d="M9.99992 9.99935C9.08325 9.99935 8.33325 9.24935 8.33325 8.33268C8.33325 7.41602 9.08325 6.66602 9.99992 6.66602C10.9166 6.66602 11.6666 7.41602 11.6666 8.33268C11.6666 9.24935 10.9166 9.99935 9.99992 9.99935ZM14.9999 8.49935C14.9999 5.47435 12.7916 3.33268 9.99992 3.33268C7.20825 3.33268 4.99992 5.47435 4.99992 8.49935C4.99992 10.4493 6.62492 13.0327 9.99992 16.116C13.3749 13.0327 14.9999 10.4493 14.9999 8.49935ZM9.99992 1.66602C13.4999 1.66602 16.6666 4.34935 16.6666 8.49935C16.6666 11.266 14.4416 14.541 9.99992 18.3327C5.55825 14.541 3.33325 11.266 3.33325 8.49935C3.33325 4.34935 6.49992 1.66602 9.99992 1.66602Z"
                                  fill="black"
                                  fill-opacity="0.45"
                                />
                              </svg>
                            </span>{' '}
                            {dataEnterprise?.Location ?? ''}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="loadingBox"></div>
                    </>
                  )}
                  <div>
                    <div className="flex border-b-1 border-gray-100">
                      <button
                        className={
                          'text-caption mt-2 lg:mt-auto lg:text-2xl font-bold pb-2 w-[150px] max-w-full text-[#F15C00] border-b-2'
                        }
                      >
                        Detalhes
                      </button>
                      <button
                        className={
                          'text-caption mt-2 lg:mt-auto lg:text-2xl font-bold pb-2 w-[150px] max-w-full text-[#000000A6] '
                        }
                      >
                        Galeria
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {isSuccessEnterpriseData && dataEnterprise ? (
            <>
              <div className="mt-10 px-5">
                <BaseInfos
                  Id={dataEnterprise?.Id}
                  Description={dataEnterprise?.Description}
                  StatusId={dataEnterprise?.StatusId}
                  ConstructionCompanyId={dataEnterprise?.ConstructionCompanyId}
                />
              </div>
            </>
          ) : (
            <div className='px-5 mt-4'>
              <SkeletonLoading height={50}/>
            </div>
          )}
        </>
      </div>
    </>
  )
}
