import React from 'react'
import Icon from '../../assets/icons/icon-differentials-ex.webp'
import Arrow from '../../assets/icons/icon-arrow-up.svg'
import { Suspense, useEffect, useState } from 'react'
import clsx from 'clsx'
import SkeletonLoading from '../common/SkeletonLoading'
import { v4 } from 'uuid'
import { strToSlug } from '../../common_functions'
import enterpriseApi from '../../store/reducers/enterprise/enterpriseApi'

export interface Props {
  EnterpriseId: string
}
export default function FinancingConditions({ EnterpriseId }: Props) {
  const [hiddenContent, setHiddenContent] = useState(true)
  const { data, isError, isSuccess, isLoading } =
    enterpriseApi.useGetEnterprisePaymentConditionByIdQuery(EnterpriseId ?? '')

  return (
    <>
      {(isLoading) && <SkeletonLoading height={200} />}
      {isSuccess && data?.Data.length > 0 && (
        <>
          <section
            className="p-3 pl-0"
            id={'navInternEnterpriseFinancingConditions'}
          >
            <h3 className="font-bold text-xl md:text-2xl my-5 text-[#4D4D4D]">
              Condições de Financiamento
            </h3>
            {/* CONTENT */}
            <div
            >
              <>
                {data?.Data?.map((item) => {
                  return (
                    <div
                      key={`${strToSlug(item.Id)}`}
                      className="rounded-2xl p-2 m-2 inline-flex w-fit border-1 border-[#0059FF]"
                    >
                      <h5 className="text-base text-[#0059FF] font-[500]">{item.PaymentCondition.Name}</h5>
                    </div>
                  )
                })}
              </>
            </div>
          </section>
          <hr className="z-[-1] relative opacity-10 mt-4" />
        </>
      )}
    </>
  )
}
