import React, { useEffect, useState } from 'react'

import { v4 } from 'uuid'

import { BannerCarouselInternEnterprise } from '../../Banners/BannerCarouselInternEnterprise'
import NotFoundImg from '../../../assets/Image-not-found.webp'
import enterpriseApi from '../../../store/reducers/enterprise/enterpriseApi'
import SkeletonLoading from '../../common/SkeletonLoading'
import FavoriteEnterprise from '../../FavoriteEnterprise'
import { EnterprisePhotos } from '../../../interfaces/enterprise/enterprisePhotos'
import { isValidStr } from '../../../common_functions'

interface Props {
  EnterpriseId: string
}

let id = v4()

interface Item {
  FileUrl: string
}

export default function NewCarouselEnterprise({ EnterpriseId }: Props) {
  const { data, isSuccess, isError, isLoading } = enterpriseApi.useGetEnterprisesMediasPhotosByIdQuery(
    EnterpriseId ?? ''
  )
  const [bannerUrls, setBannerUrls] = useState<{ [key: string]: string }>({})
  const [currentBannerIndex, setCurrentBannerIndex] = useState<number>(0)

  function checkImagemBanner(imgUrl: string): Promise<string> {
    return new Promise((resolve) => {
      const img = new Image()
      img.src = imgUrl
      img.onload = () => {
        resolve(imgUrl)
      }
      img.onerror = () => {
        resolve(NotFoundImg) // Utilize a URL ou caminho da imagem NotFoundImg
      }
    })
  }

  useEffect(() => {
    if (isSuccess && data?.Data != undefined) {
      const fetchBannerUrls = async () => {
        const tempUrls: { [key: string]: string } = {}
        for (const item of data?.Data) {
          const url: string = await checkImagemBanner(item.FileUrl)
          tempUrls[item.FileUrl] = url
        }
        setBannerUrls(tempUrls)
      }

      fetchBannerUrls()
    }
  }, [isSuccess])

  const handleNextBannerCarousel = () => {
    if (currentBannerIndex < (data?.Data?.length ?? 0) - 1)
      setCurrentBannerIndex(currentBannerIndex + 1)
  }

  const handlePrevBannerCarousel = () => {
    if (currentBannerIndex != 0) setCurrentBannerIndex(currentBannerIndex - 1)
  }

  return (
    <div key={EnterpriseId}>
      {isLoading && <SkeletonLoading height={300} />}
      {isSuccess && data?.Data?.length > 0 && (
        <div className="grid grid-cols-3 gap-4 w-full">
          {/* mainbanner */}
          <div
            className={
              data?.Data.slice(currentBannerIndex + 1, currentBannerIndex + 4).length > 0
                ? 'col-span-2'
                : 'col-span-3'
            }
          >
            {!isValidStr(bannerUrls[data?.Data[currentBannerIndex]?.FileUrl ?? '']) ? (
              <SkeletonLoading className="aspect-[4/2]" />
            ) : (
              <div
                style={{
                  backgroundImage: `url('${
                    bannerUrls[data?.Data[currentBannerIndex]?.FileUrl ?? '']
                  }')`
                }}
                className="w-full aspect-[4/2] bg-no-repeat bg-cover bg-center"
              >
                {/* left arrow carousel */}
                <button
                  className="inline-block h-full disabled:opacity-75"
                  disabled={currentBannerIndex == 0}
                  onClick={handlePrevBannerCarousel}
                >
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    className="inline-block h-full"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="36"
                      y="36"
                      width="36"
                      height="36"
                      transform="rotate(-180 36 36)"
                      fill="#0A0A0A"
                      fill-opacity="0.8"
                    />
                    <path
                      d="M23.8848 9.77L22.1148 8L12.1148 18L22.1148 28L23.8848 26.23L15.6548 18L23.8848 9.77Z"
                      fill="white"
                    />
                  </svg>
                </button>

                <div className="float-right min-w-[50px] m-4">
                  <FavoriteEnterprise enterpriseId={EnterpriseId} />
                </div>

                {/* right arrow carousel */}
                <button
                  className="inline-block h-full float-right -mr-[81px] disabled:opacity-75"
                  disabled={currentBannerIndex == (data?.Data.length ?? 0) - 1}
                  onClick={handleNextBannerCarousel}
                >
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    className=""
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="36" height="36" fill="#0A0A0A" fill-opacity="0.8" />
                    <path
                      d="M12.1152 26.23L13.8852 28L23.8852 18L13.8852 8L12.1152 9.77L20.3452 18L12.1152 26.23Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            )}
          </div>
          <div className="col-span-1 grid gap-4">
            {data?.Data.slice(currentBannerIndex + 1, currentBannerIndex + 4).map((item, index) => {
              return (
                <>
                  {!isValidStr(bannerUrls[item.FileUrl]) ? (
                    <>
                      <SkeletonLoading height={'100%'} />
                    </>
                  ) : (
                    <>
                      <div
                        style={{ backgroundImage: `url('${bannerUrls[item.FileUrl]}')` }}
                        className="w-full bg-no-repeat bg-cover bg-center"
                      >
                        <button
                          className='w-full h-full'
                          onClick={() => setCurrentBannerIndex(index + currentBannerIndex + 1)}
                          disabled={
                            index == 2 && data?.Data?.length - 4 - (currentBannerIndex + 1) > 0
                          }
                        >
                          {index == 2 && data?.Data?.length - 4 - (currentBannerIndex + 1) > 0 && (
                            <div
                              className="w-full h-full bg-[#000000CC] text-6xl text-white text-center font-bold"
                              style={{ alignContent: 'center' }}
                            >
                              <div className="-mt-2">
                                +{data?.Data?.length - 4 - (currentBannerIndex + 1)}
                              </div>
                            </div>
                          )}
                        </button>
                      </div>
                    </>
                  )}
                </>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
