import React, { useState } from 'react'
import { Suspense, lazy, useEffect } from 'react'
import { Button } from '../../common'
import enterpriseApi from '../../../store/reducers/enterprise/enterpriseApi'
import { Enterprise2 } from '../../../interfaces/enterprise/enterprise_2'
import { IContentCard } from '../../../interfaces/enterprise/argsApi'
import CardEnterprise from '../../Cards/CardEnterprise'
import CarouselEnterprise2 from '../../Carousels/CarouselEnterprise2'
import { IFavoriteEnterpriseClaim } from '../../../interfaces/users/favoriteEnterpriseClaim'
import { isMobile } from '../../../common_functions'
import { v4 } from 'uuid'
import CarouselTinySliderChildren from '../../Carousels/CarouselTinySliderChildren'

interface Props {
  size: number
  seeMore?: boolean
  marginRowCardsClassName?: string
  isCarousel?: boolean
  isFavorites?: boolean
  cardRemoveItemFavorite?: (elementObj: IFavoriteEnterpriseClaim) => void
  is3CardsByLine?: boolean
  listEnterprises?: Enterprise2[]
  paddingCarousel?: boolean
}

export default function SectionEnterprises({
  size,
  seeMore,
  isCarousel = false,
  marginRowCardsClassName = 'py-10',
  isFavorites = false,
  is3CardsByLine = true,
  cardRemoveItemFavorite,
  paddingCarousel = true,
  listEnterprises = undefined
}: Props) {
  const SkeletonLoading = lazy(() => import('../../../components/common/SkeletonLoading'))
  const id = v4()
  const [useEnterprises, { data, isError, isSuccess, isLoading, error }] =
    enterpriseApi.useGetCardsEnterprisesMutation()
  const [selectedIndexCarousel, setSelectedIndexCarousel] = useState(0)

  let touchStartX: number = 0
  let touchEndX: number = 0

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX = e.changedTouches[0].screenX
  }

  const handleTouchMove = (e: React.TouchEvent) => {
    touchEndX = e.changedTouches[0].screenX
  }

  const handleTouchEnd = () => {
    if (touchStartX - touchEndX > 50) {
      if (selectedIndexCarousel < (listEnterprises?.length ?? 0) - 1) {
        setSelectedIndexCarousel(selectedIndexCarousel + 1)
      }else{
        setSelectedIndexCarousel(0)
      }
    }

    if (touchStartX - touchEndX < -50) {
      // Swiped right
      if (selectedIndexCarousel > 0) {
        // Certifique-se de não ir abaixo de 0
        setSelectedIndexCarousel(selectedIndexCarousel - 1)
      }else{
        if(listEnterprises) setSelectedIndexCarousel(listEnterprises?.length - 1)
      }
    }
  }

  useEffect(() => {
    if (listEnterprises == undefined || listEnterprises.length < 1) {
      getEnterprises()
    }
  }, [listEnterprises])

  const getEnterprises = async () => {
    const content: IContentCard = {
      stateId: null,
      cityId: null,
      districtId: null,
      statusId: null,
      priceMaximumValue: null,
      priceMinimumValue: null,
      numberOfRooms: null
    }
    await useEnterprises(content)
  }

  if (isLoading) {
    return <div id="loadingBox"></div>
  }

  if (isError) {
    return (
      <div
        style={{ backgroundColor: 'rgba(255, 237, 213, 0.2)' }}
        className=" border-l-4 border-orange-500 text-orange-700 p-4"
        role="alert"
      >
        <p className="font-bold">Houve um erro ao carregar os empreendimentos!</p>
        <p>
          <a href={location.href}>Tente atualizar a página.</a>
        </p>
      </div>
    )
  }

  if (isCarousel && listEnterprises != undefined) {
    return (
      <div className={`${paddingCarousel ? 'pt-10' : ''}`}>
        {listEnterprises[selectedIndexCarousel] != undefined && (
          <div
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <CardEnterprise
              enterprise={listEnterprises[selectedIndexCarousel]}
              classNameCard="grid h-full"
            />
          </div>
        )}
        <div className="w-full text-center mt-0 py-3">
          {listEnterprises.length > 0 && (
            <>
              {listEnterprises.slice(0, size).map((item, index) => {
                return (
                  <button
                    onClick={() => setSelectedIndexCarousel(index)}
                    className={`bullet ${
                      selectedIndexCarousel == index ? 'bg-[#0059FF]' : 'bg-[#00000033]'
                    } m-auto w-[8px] aspect-square mx-[5px] rounded-full`}
                  ></button>
                )
              })}
            </>
          )}
        </div>
        {/* <CarouselTinySliderChildren id='tinySliderCarouselCardEnterprises'>
          {
            listEnterprises?.slice(0, size).map((item) => {
              return <CardEnterprise enterprise={item} classNameCard='grid h-full'/>
            })
          }
        </CarouselTinySliderChildren> */}
        {/* <CarouselEnterprise2 listEnterprises={listEnterprises} size={size} /> */}
      </div>
    )
  } else {
    if (listEnterprises == undefined) {
      return (
        <>
          <>
            {isSuccess && data != undefined ? (
              <>
                <div
                  className={`h-full ${
                    is3CardsByLine ? 'lg:grid lg:grid-cols-3' : 'lg:grid lg:grid-cols-2'
                  }  gap-8`}
                >
                  {data.Data.slice(0, size).map((item: Enterprise2, index: number) => {
                    return (
                      <div className="w-full h-full m-auto mt-10" key={item.Id + id}>
                        <div className="h-full">
                          <Suspense fallback={<SkeletonLoading />}>
                            <CardEnterprise
                              enterprise={item}
                              removeFavorite={
                                cardRemoveItemFavorite != undefined
                                  ? cardRemoveItemFavorite
                                  : undefined
                              }
                              isFavoriteDefault={isFavorites ? true : undefined}
                            />
                          </Suspense>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="w-[289px] max-w-full m-auto mt-12">
                  {seeMore ? (
                    <a href={'/empreendimentos'}>
                      <Button variant="primary" children="VER MAIS IMÓVEIS" className="w-full" />
                    </a>
                  ) : (
                    <div></div>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        </>
      )
    } else {
      return (
        <>
          {
            <>
              <>
                <div
                  className={`h-full ${
                    is3CardsByLine ? 'lg:grid lg:grid-cols-3' : 'lg:grid lg:grid-cols-2'
                  } gap-8`}
                >
                  {listEnterprises?.slice(0, size).map((item: Enterprise2, index: number) => {
                    return (
                      <Suspense key={item.Slug + v4()} fallback={<SkeletonLoading />}>
                        <div
                          className={`w-full h-full ${marginRowCardsClassName}`}
                          key={item.Id}
                        >
                          <div className={isMobile() ? 'mt-0' : 'grid content-top h-full'}
                            // style={{boxShadow: '0px -1px 5px -4px black'}}
                            >
                            <CardEnterprise
                              enterprise={item}
                              isFavoriteDefault={isFavorites ? true : undefined}
                            />
                          </div>
                        </div>
                      </Suspense>
                    )
                  })}
                </div>
                {seeMore ? (
                  <div className="w-[289px] max-w-full m-auto mt-12">
                    <a href={'/busca'}>
                      <Button
                        id="seeMoreEnterprises"
                        variant="primary"
                        children="VER MAIS IMÓVEIS"
                        className="w-full"
                      />
                    </a>
                  </div>
                ) : (
                  <div></div>
                )}
              </>
            </>
          }
        </>
      )
    }
  }
}
