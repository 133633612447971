import { Button, Checkbox } from '../../common'
import React from 'react'
import ContactImg from '../../../assets/contact-img.webp'
import TextInputField from '../../common/TextInputField'
import ReCAPTCHA from 'react-google-recaptcha'
import { Suspense, useEffect, useState } from 'react'
import { PopupContactRequest } from '../../Popups/PopupContactRequest'
import leadApi from '../../../store/reducers/leads/leadApi'
import { ILead } from '../../../interfaces/lead'
import { Cookies } from 'react-cookie'
import IconLoading from '../../../assets/loading-gif.gif'
import {
  DATE_ENTRY,
  DEVICE_ID,
  EMAIL,
  FORM_INFOS_CONTACT_LEAD,
  ID_PRODUCT_SEARCHED_VIEWED
} from '../../../constants/storageNames'
import Logo from '../../../assets/glemo-logo.webp'
import { v4 } from 'uuid'
import SkeletonLoading from '../../common/SkeletonLoading'
import { isLocal, isValidStr } from '../../../common_functions'
import { useAuth } from '../../../hooks/useAuth'
import { IRequestCreate } from '../../../store/reducers/users/interfaces/IRequestApi'
import userApi from '../../../store/reducers/users/userApi'
import { USERINFOS } from '../../../constants/loginInfos'
import generalApi from '../../../store/reducers/general/generalApi'
import { IProductSearchedViewedUpdt } from '../../../interfaces/productSearchedViewed'
import TagPromotion from '../../../assets/tag_promo.webp'

interface Props {
  enterpriseId: string
  constructionId: string
  formId: string
  isPromotion?: boolean
}

export function CardFormContactLead({ enterpriseId, constructionId, formId, isPromotion = false }: Props) {
  const auth = useAuth()
  const cookies = new Cookies()
  const deviceId = cookies.get(DEVICE_ID)
  const navigationStartDate = sessionStorage.getItem(DATE_ENTRY) ?? ''
  const formInfosLocal: IRequestCreate = JSON.parse(
    localStorage.getItem(FORM_INFOS_CONTACT_LEAD) &&
      localStorage.getItem(FORM_INFOS_CONTACT_LEAD)?.toString() != 'undefined'
      ? localStorage.getItem(FORM_INFOS_CONTACT_LEAD) ?? '{}'
      : '{}'
  )
  const uuid = v4()
  const [disabledBtn, setDisabledBtn] = useState<boolean>(!isLocal())
  const userInfos = Array.isArray(auth.loginUser) ? auth.loginUser[0] : auth.loginUser
  const isLogged = isValidStr(userInfos?.User?.Id)
  const typeLeadSourceIdTenhoIntesse = 'ACB81872-EA1E-4AD0-B54E-80B555E49AE0'
  const statusLead = 'DCF1D21E-8319-4B94-BF64-96B86213F0C7'
  const [isNewAccount, setIsNewAccount] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [statusMessage, setStatusMessage] = useState('loading')
  const [messagePopup, setMessagePopup] = useState<string | undefined>(undefined)

  const [
    useUpdtProductSearchedViewed,
    {
      data: dataProductSearched,
      isLoading: isLoadingProductSearched,
      isError: isErrorProductSearched,
      isSuccess: isSuccessProductSearched
    }
  ] = generalApi.useLazyGetProductSearchedViewedQuery()
  const [content, setContent] = useState<ILead>({
    statusLead: statusLead,
    name: formInfosLocal?.fullName ?? '',
    cpf: '',
    email: formInfosLocal?.email ?? '',
    phone: formInfosLocal?.phoneNumber ?? '',
    enterpriseId: enterpriseId,
    constructionCompanyId: constructionId,
    typeLeadSourceId: typeLeadSourceIdTenhoIntesse,
    metadata: {
      redirectionSourceUrl: uuid,
      navigationStartDate: navigationStartDate,
      urlSubmittedForm: location.href,
      deviceId: parseInt(deviceId),
      enterpriseId: enterpriseId,
      traceId: uuid,
      allowDirectContact: true
    }
  })

  const [
    useAddLeads,
    { isError: isErrorAddLead, isLoading: isLoadingAddLead, error, isSuccess: isSuccessAddLead }
  ] = leadApi.useAddLeadMutation()

  const [
    useCreateUser,
    {
      data: dataCreateUser,
      error: errorCreateUser,
      isError: isErrorCreateUser,
      isLoading: isLoadingCreateUser,
      isSuccess: isSuccessCreateUser
    }
  ] = userApi.useCreateUserMutation()

  const createUser = async () => {
    let newUserCreate: IRequestCreate = {
      email: content.email,
      fullName: content.name,
      phoneNumber: content.phone
    }

    await useCreateUser(newUserCreate)
  }

  const updtProductSearchedViewed = async () => {
    const id = sessionStorage.getItem(ID_PRODUCT_SEARCHED_VIEWED)
    if (id) {
      await useUpdtProductSearchedViewed(id)
    }
  }

  useEffect(() => {
    if (isSuccessProductSearched) {
      sessionStorage.removeItem(ID_PRODUCT_SEARCHED_VIEWED)
    }
  }, [isSuccessProductSearched])

  const handleSendMessage = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setShowPopup(true)
    await sendLead()
    await updtProductSearchedViewed()
    if (!isLogged) {
      await createUser()
    }
  }

  const sendLead = async () => {
    var newPhone = content.phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')
    var contentAux = content
    contentAux.phone = newPhone.slice(0, 11)
    await useAddLeads(contentAux)
  }

  useEffect(() => {
    if (!isLoadingAddLead && !isLoadingCreateUser && !isLoadingProductSearched) {
      const contentLead: IRequestCreate = {
        fullName: content.name,
        email: content.email,
        phoneNumber: content.phone
      }
      localStorage.setItem(FORM_INFOS_CONTACT_LEAD, JSON.stringify(contentLead))

      if (isSuccessAddLead || isErrorAddLead) setShowPopup(true)
      if (isLogged) {
        if (isSuccessAddLead) setStatusMessage('success')
        if (isErrorAddLead) setStatusMessage('error')
      } else {
        if (isSuccessAddLead && (isSuccessCreateUser || isErrorCreateUser)) {
          setStatusMessage('success')
          if (isSuccessCreateUser) {
            setMessagePopup(
              'Oba! Agora você faz parte do glemO, confirme sua conta no e-mail cadastrado para ver sua jornada completa nesse empreendimento. A construtora entrará em contato em breve. Se construtora não entrar em contato nós cuidaremos de você!'
            )
          }
          if (isErrorCreateUser) {
            setMessagePopup(
              `Oba! A construtora entrará em contato em breve. Se construtora não entrar em contato nós cuidaremos de você!`
            )
          }
        }
        if (isErrorAddLead) {
          setStatusMessage('error')
        }
      }
    }
  }, [
    isSuccessAddLead,
    isLoadingCreateUser,
    isLoadingProductSearched,
    isLoadingAddLead,
    isErrorAddLead,
    isSuccessCreateUser,
    isErrorCreateUser
  ])

  // useEffect(() => {
  //   if (isSuccessAddLead) setStatusMessage('success')
  // }, [isSuccessAddLead])

  // useEffect(() => {
  //   if (isErrorAddLead) {
  //     setStatusMessage('error')
  //   }
  // }, [isErrorAddLead])

  const handleCheckedContatoDireto = () => {
    let checkboxHTML = document.querySelector('#checkboxModalFormContact') as HTMLElement
    let isChecked = false
    if (checkboxHTML != undefined) {
      isChecked = checkboxHTML.getAttribute('aria-checked') === 'false'
    }
    let metadaAux = content.metadata
    metadaAux.allowDirectContact = isChecked
    setContent({ ...content, metadata: metadaAux })
  }

  function handleChangeStatusModalPopup() {
    setShowPopup(false)
    setStatusMessage('loading')
    setMessagePopup(undefined)
  }

  function onChange(value: any) {
    if (value) {
      setDisabledBtn(false)
    }
  }

  return (
    <>
      <>
        <div className='border border-gray-100 rounded-xl mt-5 lg:mt-0 lg:border-0 p-4 lg:p-0'>
          <div
            className="flex items-start justify-between"
          >
            {isPromotion && <img src={TagPromotion} width={80} className='absolute right-4 top-0 -mt-[7px]'/>}

            <h3 className="text-2xl font-semibold lg:mt-4 mb-4">
              Tenho interesse neste imóvel
            </h3>
          </div>
          {/*body*/}
          <div className="relative pt-2 flex-auto">
            <form id={formId} onSubmit={handleSendMessage} method='post'>
              <div className="grid grid-rows-1 gap-4">
                {/* <SelectField
                            name="subject"
                            label="Assunto"
                            options={['Financeiro', 'Parcerias', 'Suporte', 'Comercial', 'Outros']}
                          /> */}
                <TextInputField
                  name="name"
                  label="Nome*"
                  required
                  defaultValue={content.name}
                  setInput={(val) => setContent({ ...content, name: val })}
                />
                {/* <TextInputField
                            name="cpf"
                            label="CPF*"
                            required
                            type="number"
                            setInput={(val) => setContent({ ...content, cpf: val })}
                            maxLength={11}
                          /> */}
                <TextInputField
                  name="email"
                  label="E-mail*"
                  required
                  type="email"
                  defaultValue={content.email}
                  setInput={(val) => setContent({ ...content, email: val })}
                />
                <TextInputField
                  name="phone-number"
                  label="Telefone"
                  defaultValue={content.phone}
                  maskPhoneNumber
                  setInput={(val) => setContent({ ...content, phone: val })}
                />
                {/* <SelectField name="contact" label="Com quem vou conversar?" /> */}
              </div>
              <div>
                <div className="flex">
                  <Checkbox.Root
                    id="checkboxModalFormContact"
                    className="m-auto ml-0 mr-3 h-[18px]"
                    variant="orange"
                    onClick={handleCheckedContatoDireto}
                  >
                    <Checkbox.Indicator>
                      {/* <Check color="#ffffff" size={12} weight="bold" /> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        fill="#ffffff"
                        viewBox="0 0 256 256"
                      >
                        <path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path>
                      </svg>
                    </Checkbox.Indicator>
                  </Checkbox.Root>
                  <small className="font-semibold text-black text-lg my-4">
                    Este número é meu WhatsApp e permito o contato direto
                  </small>
                </div>
              </div>
              <div>
                <ReCAPTCHA sitekey="6LeCvu8pAAAAAIDJt1wUUvPkKN6y-7QvDZnjl5Ln" onChange={onChange} />
              </div>
              <div className="my-4">
                {isNewAccount && (
                  <div
                    className="bg-orange-50 border-l-4 border-orange-500 text-orange-700 p-4"
                    role="alert"
                  >
                    <p>
                      Verifique seu e-mail, confirme sua conta, depois atualize a página e demonstre
                      interesse nos empreendimentos.
                    </p>
                  </div>
                )}
              </div>
              <div className="text-center mt-4">
                <p className="text-base font-semibold text-gray-600 leading-[22px]">
                  Ao enviar seus dados para a construtora, automaticamente você estará aceitando
                  nossos{' '}
                  <span>
                    <a href="/termos-de-uso" className="text-blue-700 underline">
                      termos de uso
                    </a>
                  </span>{' '}
                  da plataforma
                </p>
              </div>
              <div className="flex items-center justify-center mt-5">
                <Button
                  disabled={disabledBtn}
                  variant="orange"
                  type='submit'
                  id="sendMessageModalFormContact"
                  className="font-bold w-full btn-submit-modal-form-contact"
                >
                  ENVIAR INFORMAÇÕES{' '}
                  {isLoadingCreateUser || isLoadingAddLead ? (
                    <img src={IconLoading} width={15} />
                  ) : (
                    <></>
                  )}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </>
      <>
        {/* POPUP */}
        <PopupContactRequest
          status={statusMessage}
          showPopup={showPopup}
          message={messagePopup}
          closePopup={handleChangeStatusModalPopup}
        />
      </>
    </>
  )
}
