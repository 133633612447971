import React, { useState } from 'react'
// import { X } from 'phosphor-react'
import { Button } from '../../common'
import LogoGlemo from '../../../assets/glemo-logo.webp'
import Loading from '../../../assets/loading-gif.gif'
import { Suspense, useEffect } from 'react'
import SkeletonLoading from '../../common/SkeletonLoading'
import { Navigate, useNavigate } from 'react-router-dom'
interface Props {
  showPopup?: boolean
  closePopup?: () => void
  status: string //'error' | 'success' | 'loading'
  title?: string
  titleError?: string
  titleLoading?: string
  titleSuccess?: string
  message?: string
  messageError?: string
  messageSuccess?: string
}

const defaultTitleSuccess = 'Solicitação de contato enviada!'
const defaultTitleError = 'Solicitação de contato não enviada!'
const defaultTitleLoading = 'Sua mensagem está sendo enviada...'

const defaultMessageSuccess =
  'A construtora entrará em contato em breve. Se construtora não entrar em contato nós cuidaremos de você!'
const defaultMessageError =
  'Houve um erro ao enviar sua mensagem, por favor, tente novamente mais tarde!'
const defaultMessageLoading = ''

export function PopupContactRequest({
  showPopup,
  closePopup,
  status,
  title,
  message,
}: Props) {
  const [titlePopup, setTitlePopup] = useState(title)
  const [messagePopup, setmessagePopup] = useState(message)
  const navigate = useNavigate();

  const redirectToHome = () => {
    window.location.reload();
  };

  function getDefaultMessage() {
    if (message == undefined) {
      if (status == 'loading') return defaultMessageLoading
      return status == 'error'
        ? defaultMessageError
        : defaultMessageSuccess
    }
    return message
  }

  function getDefaultTitle() {
    if (title == undefined) {
      if (status == 'loading') return defaultTitleLoading
      return status == 'error'
        ? defaultTitleError
        : defaultTitleSuccess
    }
    return title
  }


  useEffect(() => {
    setmessagePopup(getDefaultMessage())
    setTitlePopup(getDefaultTitle())
  }, [status])

  // useEffect(() => {
  //   const closePopupContactRequest = document.querySelector(
  //     '#closePopupContactRequest'
  //   ) as HTMLElement
  //   closePopupContactRequest?.addEventListener('click', () => closePopup)
  //   const concluirPopupContactRequest = document.querySelector(
  //     '#concluirPopupContactRequest'
  //   ) as HTMLElement
  //   concluirPopupContactRequest?.addEventListener('click', () => closePopup)
  // }, [closePopup])

  useEffect(() => {
    if (location.href.includes('/empreendimentos')) {
      let modalContactHTML = document.querySelector('.modal-form-contact') as HTMLElement
      if (modalContactHTML != undefined) {
        if (showPopup) {
          modalContactHTML.classList.remove('z-[1000]')
        } else {
          modalContactHTML.classList.add('z-[1000]')
        }
      }
    }
  }, [showPopup])

  const handleChangeShowModal = () => {}

  return (
    <>
      {showPopup ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[10001] outline-none focus:outline-none w-[372px] max-w-full m-auto">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-xl shadow-xl relative flex flex-col w-full bg-white outline-none focus:outline-none popup-contact-request">
                <div className="flex items-start justify-center p-5 rounded-t">
                  {(
                    <img

                      src={LogoGlemo}
                      className="w-1/3 m-auto text-center min-w-[125px] max-w-full"
                    />
                  ) || <SkeletonLoading />}
                  <div className="absolute right-5">
                    <button
                      id="closePopupContactRequest"
                      onClick={closePopup}
                      className="p-1 ml-auto bg-transparent border-0 float-right leading-none font-semibold outline-none focus:outline-none"
                    >
                      <span className="bg-transparent h-6 w-6 outline-none focus:outline-none">
                        {/* <X color="#5C5C5C" size={26} weight="bold" /> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={26}
                          fill={'#5C5C5C'}
                          viewBox="0 0 256 256"
                        >
                          <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
                {/*body*/}
                <div className="relative p-6 pt-0 flex-auto text-center">
                  {status == 'loading' ? (
                    <div>
                      <Suspense fallback={<SkeletonLoading />}>
                        <img
                          alt="Icon Loading"

                          src={Loading}
                          className="w-[50px] m-auto mb-5"
                        />
                      </Suspense>
                    </div>
                  ) : null}
                  <h5 className="text-gray-600 font-bold text-xl">{titlePopup}</h5>
                  <p
                    className="font-medium text-black text-xl mt-5"
                    dangerouslySetInnerHTML={{ __html: messagePopup ?? '' }}
                  ></p>
                </div>
                {/*footer*/}
                {status != 'loading' ? (
                  <div className="flex items-center justify-center pb-9 px-5">
                    <Button
                      variant="orange"
                      onClick={() => {
                        closePopup;
                        redirectToHome();
                      }}
                      id="concluirPopupContactRequest"
                      className="font-bold w-full"
                    >
                      FECHAR
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-50 bg-black"></div>
        </>
      ) : null}
    </>
  )
}
