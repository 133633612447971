import IconFavorite from '../../assets/icons/icon-favorite.svg'
import IconFavoriteFull from '../../assets/icons/icon-favorite-full.svg'
import IconLoading from '../../assets/loading-gif.gif'
import React from 'react'
import { Suspense, useEffect, useState } from 'react'
import { Enterprise2 } from '../../interfaces/enterprise/enterprise_2'
import SkeletonLoading from '../common/SkeletonLoading'
import { RELOAD_PAGE } from '../../constants/storageNames'
import userApi from '../../store/reducers/users/userApi'
import { useAuth } from '../../hooks/useAuth'
import { IFavoriteEnterpriseToken } from '../../interfaces/users/favoriteEnterpriseToken'
import { isValidStr } from '../../common_functions'
import { USER_CLAIM_FAVORITES_ENTERPRISES } from '../../constants/userClaims'
import { IFavoriteEnterpriseClaim } from '../../interfaces/users/favoriteEnterpriseClaim'
import { USERINFOS, USER_FAVORITES_ENTERPRISES } from '../../constants/loginInfos'
import { ModalError } from '../Modais/ModalError'

interface Props {
  enterpriseId: string
  enterpriseTitle?: string
  isFavoriteDefault?: boolean | undefined
  removeFavorite?: (elementObj: IFavoriteEnterpriseClaim) => void | undefined
}
export default function FavoriteEnterprise({
  enterpriseId,
  enterpriseTitle,
  isFavoriteDefault = undefined,
  removeFavorite
}: Props) {
  //CODIGO DOS FAVORITOS
  const auth = useAuth()
  const reloadPageLocal = parseInt(sessionStorage.getItem(RELOAD_PAGE) ?? '0')
  const [errorFavoriteLocal, seterrorFavoriteLocal] = useState(false)
  const userInfos = Array.isArray(auth.loginUser) ? auth.loginUser[0] : auth.loginUser
  const [showModalError, setShowModalError] = useState(false)
  const [favoriteEnterprises, setfavoriteEnterprises] = useState<IFavoriteEnterpriseClaim[]>(userInfos ? JSON.parse(localStorage.getItem(USER_FAVORITES_ENTERPRISES) ?? "[]") : [])

  const handleCloseModal = () => {
    setShowModalError(false)
  }

  // useEffect(() => {
  //   if (auth.authenticated && !isValidStr(auth.loginUser[0].AccessToken) && reloadPageLocal <= 3) {
  //     window.location.reload()
  //     let counterReload = parseInt(sessionStorage.getItem(RELOAD_PAGE) ?? '0')
  //     counterReload += 1
  //     sessionStorage.setItem(RELOAD_PAGE, counterReload.toString())
  //   } else if (reloadPageLocal > 3) {
  //     seterrorFavoriteLocal(true)
  //   }
  // }, [])

  const [isFavoriteEnterprise, setIsFavoriteEnterprise] = useState(
    isFavoriteDefault != undefined ? isFavoriteDefault : false
  )

  useEffect(() => {
    if (typeof favoriteEnterprises === 'string') {
      setfavoriteEnterprises(JSON.parse(favoriteEnterprises))
    }
  }, [favoriteEnterprises])

  useEffect(() => {}, [isFavoriteDefault])

  useEffect(() => {
    setfavoriteEnterprises(getFavoritesEnterprises())
  }, [window.location])

  const [
    useFavoriteEnterprise,
    { isSuccess: isSuccessFavorite, isError: isErrorFavorite, isLoading: isLoadingFavorite }
  ] = userApi.useAddFavoriteEnterpriseMutation()
  const [
    useFavoriteDeleteEnterprise,
    {
      isSuccess: isSuccessFavoriteDelete,
      isError: isErrorFavoriteDelete,
      error: errorFavoriteDelete,
      isLoading: isLoadingFavoriteDelete
    }
  ] = userApi.useDeleteFavoriteEnterpriseMutation()

  useEffect(() => {
    if (isErrorFavoriteDelete) handleSuccessFavoriteDelete(true)
  }, [isErrorFavoriteDelete])

  const getFavoritesEnterprises = () => {
    var favoritesAux: IFavoriteEnterpriseClaim[] = auth.loginUser[0] != null && auth.loginUser[0].User != null ? JSON.parse(localStorage.getItem(USER_FAVORITES_ENTERPRISES) ?? "[]") : []
    return favoritesAux
  }

  const handlefavoriteEnterprise = async (fav: boolean) => {
    if (!errorFavoriteLocal) {
      const newEnterprise: IFavoriteEnterpriseClaim = {
        EnterpriseId: enterpriseId,
        EnterpriseName: null
      }

      if (removeFavorite != undefined) {
        removeFavorite(newEnterprise)
      } else {
        if (auth.authenticated) {
          let userId = userInfos.User.Id
          let token = userInfos.AccessToken
          let content: IFavoriteEnterpriseToken = {
            userId: userId,
            enterpriseId: enterpriseId,
            token: token
          }
          if (fav) {
            await useFavoriteDeleteEnterprise(content)
          } else {
            await useFavoriteEnterprise(content)
          }
        } else {
          window.location.href = '/entrar'
        }
      }
    } else {
      setShowModalError(true)
      setTimeout(() => {
        setShowModalError(false)
      }, 2000)
    }
  }

  useEffect(() => {
    setIsFavoriteEnterprise(isFavoriteDefault != undefined ? isFavoriteDefault : isFavorite())
  }, [location.pathname])

  useEffect(() => {
    if (isSuccessFavorite) {
      handleSuccessFavorite(false)
    }
  }, [isSuccessFavorite])

  useEffect(() => {
    if (isErrorFavoriteDelete) {
      handleSuccessFavorite(true)
    }
  }, [isErrorFavorite])

  useEffect(() => {
    if (isSuccessFavoriteDelete) {
      handleSuccessFavoriteDelete(false)
    }
  }, [isSuccessFavoriteDelete])

  const handleSuccessFavoriteDelete = (isError: boolean) => {
    setIsFavoriteEnterprise(false)
    updateListFavorites(true)
    if (isError) {
      let messageError = "Favorito do Usuário não encontrada"
      if (window.location.pathname == '/favoritos' && errorFavoriteDelete) {
        if(JSON.stringify(errorFavoriteDelete).includes(messageError)){
          localStorage.setItem(USER_FAVORITES_ENTERPRISES, JSON.stringify(favoriteEnterprises.filter(x => x.EnterpriseId != enterpriseId)))
        }
      }
    }
    // if(window.location.pathname == '/favoritos'){
    //   setTimeout(() => {
    //     window.location.reload()
    //   }, 500);
    // }
  }

  const handleSuccessFavorite = (isError: boolean) => {
    sessionStorage.removeItem(RELOAD_PAGE)
    setIsFavoriteEnterprise(true)
    updateListFavorites(false)
    if (isError) {
      if (window.location.pathname == '/favoritos') {
        setTimeout(() => {
          window.location.reload()
        }, 500)
      }
    }
  }

  const updateListFavorites = (isDelete: boolean) => {
    const newEnterprise: IFavoriteEnterpriseClaim = {
      EnterpriseId: enterpriseId,
      EnterpriseName: null
    }
    let favoriteEnterprisesAux: IFavoriteEnterpriseClaim[] = userInfos ? JSON.parse(localStorage.getItem(USER_FAVORITES_ENTERPRISES) ?? "[]") : []
    if (isDelete) {
      favoriteEnterprisesAux = favoriteEnterprisesAux.filter(
        (x: IFavoriteEnterpriseClaim) => x.EnterpriseId != enterpriseId
      )
      setIsFavoriteEnterprise(false)
    } else {
      favoriteEnterprisesAux.push(newEnterprise)
      setIsFavoriteEnterprise(true)
    }

    setfavoriteEnterprises(favoriteEnterprisesAux)
    updateFavoritesLocalStorage(favoriteEnterprisesAux)
  }

  const updateFavoritesLocalStorage = (favoriteEnterprisesAux: IFavoriteEnterpriseClaim[]) => {
    localStorage.setItem(USER_FAVORITES_ENTERPRISES, JSON.stringify(favoriteEnterprisesAux))
  }

  const isFavorite = () => {
    if (isFavoriteDefault == undefined) {
      let isFavoriteAux = false
      let favoritesAux: IFavoriteEnterpriseClaim[] = auth.loginUser[0] != null && auth.loginUser[0].User != null ? JSON.parse(localStorage.getItem(USER_FAVORITES_ENTERPRISES) ?? "[]") : []
      favoritesAux.forEach((element) => {
        if (element.EnterpriseId == enterpriseId) {
          isFavoriteAux = true
          return
        }
      })
      return isFavoriteAux
    } else {
      return isFavoriteDefault
    }
  }

  return (
    <>
      <button className="h-full w-full">
        <div
          className="bg-[#F0F3F6] rounded h-full text-center  max-h-[30px] cursor-pointer"
          onClick={() => handlefavoriteEnterprise(isFavoriteEnterprise)}
        >
          <Suspense fallback={<SkeletonLoading />}>
            <>
              {isLoadingFavorite || isLoadingFavoriteDelete ? (
                <img
                  alt="icon-favorite"
                  width={15}
                  src={IconLoading}
                  className="inline w-[15px] h-[15px] pt-1"
                  style={{ verticalAlign: 'top' }}
                />
              ) : (
                <img
                  alt="icon-favorite"
                  src={isFavoriteEnterprise ? IconFavoriteFull : IconFavorite}
                  className="inline pt-1"
                  style={{ verticalAlign: 'top' }}
                />
              )}
            </>
          </Suspense>
        </div>
      </button>
      <ModalError
        showModal={showModalError}
        closeModal={handleCloseModal}
        title="Erro!"
        description="Houve um erro ao adicionar aos favoritos, tente logar novamente."
      />
    </>
  )
}
