import { Suspense } from 'react'
import { EnterpriseData } from '../../interfaces/enterprise/enterpriseData'
import KnowUnits from './KnowUnits'
import SkeletonLoading from '../common/SkeletonLoading'
import Downloads from './Downloads'
import { EnterpriseUnit } from '../../interfaces/enterprise/enterpriseUnit'

interface Props {
  enterprise: EnterpriseData
  units: EnterpriseUnit
}

export default function SectionKnowUnits({ enterprise, units}: Props) {
  return (
    <>
      {/* CONHECA AS UNIDADES  */}
      <Suspense fallback={<SkeletonLoading />}>
        <KnowUnits
          units={units}
        // unitsList={data?.Data.slice(0,5)}
        />
      </Suspense>
      {/* DOWNLOADS */}
      <Suspense fallback={<SkeletonLoading />}>
        <Downloads EnterpriseId={enterprise.Id} />
      </Suspense>
    </>
  )
}
