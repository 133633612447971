import React from 'react'
import Arrow from '../../assets/icons/icon-arrow-up.svg'
import { Suspense, useEffect, useState } from 'react'
import clsx from 'clsx'
import { isValidStr } from '../../common_functions'
import SkeletonLoading from '../common/SkeletonLoading'
import enterpriseApi from '../../store/reducers/enterprise/enterpriseApi'

interface EnterpriseDetailCard {
  EnterpriseId: string
}

export default function Location({ EnterpriseId }: EnterpriseDetailCard) {
  const [hiddenContent, setHiddenContent] = useState(true)
  const { data, isError, isSuccess, isLoading } = enterpriseApi.useGetEnterpriseAddressByIdQuery(
    EnterpriseId ?? ''
  )

  const extractIframeSrc = (html: string): string => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')
    const iframe = doc.querySelector('iframe')
    return iframe?.getAttribute('src') ?? ''
  }

  return (
    <>
      {isLoading && <SkeletonLoading height={150} />}
      {isSuccess && isValidStr(data?.Data.GoogleMap) ? (
        <>
          <section className="p-3 pl-0" id="navInternEnterpriseLocation">
            <div className="grid grid-cols-2 my-5">
              <h1 className="font-bold text-xl md:text-2xl text-[#4D4D4D]">
                Localização
              </h1>
              <a target='_blank' href={`https://www.google.com/maps/place/${data?.Data.Location.replace(",", "," + data?.Data.Number)}`} className="my-auto font-semibold text-caption text-end text-[#0059FF]">
                Ver no Maps
              </a>
            </div>
            <div>
              <iframe
                src={extractIframeSrc(data?.Data.GoogleMap)}
                width="100%"
                className="w-full aspect-[15/11]"
              ></iframe>
            </div>
          </section>
          <hr className="z-[-1] relative opacity-[0.1] mt-4" />
        </>
      ) : (
        <></>
      )}
    </>
  )
}
