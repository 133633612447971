import { Suspense, useEffect } from 'react'
import SkeletonLoading from '../../common/SkeletonLoading'
import SectionEnterprises from '../SectionEnterprises'
import enterpriseApi from '../../../store/reducers/enterprise/enterpriseApi'
import { Enterprise2 } from '../../../interfaces/enterprise/enterprise_2'

interface Props {
  enterpriseId: string
}

export default function SecionRecommendedEnterprises({ enterpriseId }: Props) {
  const [enterprisesRecommended, { data, isError, isLoading, isSuccess, status }] =
    enterpriseApi.useGetRecommendedEnterprisesMutation()

  useEffect(() => {
    getRecommended()
  }, [enterpriseId])

  const getRecommended = async () => {
    await enterprisesRecommended(enterpriseId)
  }

  return (
    <>
      <div className="mt-5 lg:mt-20">
        {isLoading && (
          <div className="mt-5">
            <SkeletonLoading height={500} />
          </div>
        )}
        {isSuccess && data != undefined && data?.Data.length > 0 && (
          <>
            <h2 className="font-bold text-2xl lg:text-3xl">
              <span className="lg:opacity-80">Quem viu esse empreendimento, </span>
              <span className="lg:text-[#0059FF]">viu também:</span>
            </h2>
            <>
              <div className="hidden lg:block">
                <Suspense fallback={<SkeletonLoading />}>
                  <SectionEnterprises size={3} listEnterprises={data?.Data} />
                </Suspense>
              </div>
              <div className="mt-8 lg:hidden">
                <Suspense fallback={<SkeletonLoading />}>
                  <SectionEnterprises
                    size={3}
                    paddingCarousel={false}
                    isCarousel
                    listEnterprises={data?.Data}
                  />
                </Suspense>
              </div>
            </>
          </>
        )}
      </div>
    </>
  )
}
