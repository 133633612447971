import * as RadixCheckbox from '@radix-ui/react-checkbox'
import clsx from 'clsx'
import { forwardRef, Ref } from 'react'
import React from 'react'

export interface CheckboxProps extends RadixCheckbox.CheckboxProps {
  rounded?: boolean,
  variant?: string
}

const CheckboxRoot = forwardRef(
  (
    { children, className, rounded, variant, ...props }: CheckboxProps,
    forwardedRef: Ref<HTMLButtonElement>
  ) => {
    return (
      <RadixCheckbox.Root
        ref={forwardedRef}
        {...props}
        className={clsx(
          className,
          {
            'rounded-full leading-none': rounded === true,
            'rounded-sm': !rounded,
            'state-checked:bg-blue-700' : variant === undefined || variant == '' || variant === 'primary',
            'state-checked:bg-[#F15C00]' : variant === 'orange',
          },
          'h-6 w-6 flex text-center text-xl font-semibold items-center justify-center box-border text-gray-600 bg-off-white border-1 state-checked:text-white border-gray-200 state-checked:border-none'
        )}
      >
        {children}
      </RadixCheckbox.Root>
    )
  }
)

const CheckboxIndicator = forwardRef(
  (
    { children, ...props }: RadixCheckbox.CheckboxIndicatorProps,
    forwardedRef: Ref<HTMLSpanElement>
  ) => {
    return (
      <RadixCheckbox.Indicator ref={forwardedRef} {...props}>
        {children}
      </RadixCheckbox.Indicator>
    )
  }
)

CheckboxRoot.displayName = 'Checkbox.Root'
CheckboxIndicator.displayName = 'Checkbox.Indicator'

export const Checkbox = {
  Root: CheckboxRoot,
  Indicator: CheckboxIndicator
}
