import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { DataRTKReturn, DataRTKReturnList } from '../../../interfaces/dataRTKReturn'
import { IRequestActivateOrDeactivate, IRequestArgument, IRequestChangePassword, IRequestClaimAdd, IRequestClaimRemove, IRequestCreate, IRequestFavoriteEnterprise, IRequestRoleAdd, IRequestUpdateDetails } from './interfaces/IRequestApi'
import { IResponseError, IResponseErrorNotFound } from '../interfaces/IResponseApi'
import { Data } from '@react-google-maps/api'
import { IDataMessageWithErrors, IResponseErrorList } from '../interfaces/IResponseApi'
import { IFavoriteEnterprise } from '../../../interfaces/users/favoriteEnterprise'
import { IFavoriteEnterpriseToken } from '../../../interfaces/users/favoriteEnterpriseToken'
import { UserArgumentToken, UserDetails } from '../../../interfaces/user'
import { IResponseStatus } from './interfaces/IResponseApi'

const userApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/` }),
  tagTypes: ['users'],
  endpoints: (builder) => ({
    //POST
    createUser: builder.mutation<DataRTKReturn<IResponseStatus>, IRequestCreate>({
      query: (content) => ({
        url: `/glemo/users/create`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['users'],
      transformResponse: (response:DataRTKReturn<IResponseStatus>, meta, args) => {
        return response
      },
      transformErrorResponse: (response:IResponseErrorList, meta, arg) =>{
        return response.data?.Errors[0].Error
      }
    }),
    addFavoriteEnterprise: builder.mutation<DataRTKReturn<IResponseStatus>, IFavoriteEnterpriseToken>({
      query: (content) => ({
        url: `/users/favorite-enterprise/add`,
        body: JSON.stringify({
          "userId": content.userId,
          "enterpriseId": content.enterpriseId
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${content.token}`
        }
      })
    }),
    deleteFavoriteEnterprise: builder.mutation<DataRTKReturn<IResponseStatus>, IFavoriteEnterpriseToken>({
      query: (content) => ({
        url: `/users/favorite-enterprise/delete`,
        body: JSON.stringify({
          userId: content.userId,
          enterpriseId: content.enterpriseId
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${content.token}`
        }
      }),
    }),
    testUser: builder.mutation<DataRTKReturnList<IResponseStatus>, undefined>({
      query: () => ({
        url: `/glemo/construction-company/create`,
        body: JSON.stringify({
          "legalRepresentative": "test",
          "razaoSocial": "test",
          "cnpj": "16017599000106",
          "zipCode": "32606414",
          "addressNumber": "123",
          "email": "teste@test.com",
          "mainPhone": "31999999999"
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['users']
    }),
    getByIdUser: builder.mutation<DataRTKReturn<UserDetails>, UserArgumentToken>({
      query: (content) => ({
        url: `/users/get-by-id`,
        body: JSON.stringify({
          argument: content.Argument
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${content.Token ?? ""}`
        }
      }),
      invalidatesTags: ['users']
    }),
    getByEmailUser: builder.mutation<DataRTKReturnList<UserDetails>, IRequestArgument>({
      query: (content) => ({
        url: `/users/get-by-email`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['users']
    }),
    addClaimUser: builder.mutation<DataRTKReturnList<IResponseStatus>, IRequestClaimAdd>({
      query: (content) => ({
        url: `/users/claims/add`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['users']
    }),
    removeClaimUser: builder.mutation<DataRTKReturnList<IResponseStatus>, IRequestClaimRemove>({
      query: (content) => ({
        url: `/users/claims/remove`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['users']
    }),
    addRoleUser: builder.mutation<DataRTKReturnList<IResponseStatus>, IRequestRoleAdd>({
      query: (content) => ({
        url: `/users/roles/add`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['users']
    }),
    removeRoleUser: builder.mutation<DataRTKReturnList<IResponseStatus>, IRequestClaimRemove>({
      query: (content) => ({
        url: `/users/roles/remove`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['users']
    }),
    //GET
    getAllUsers: builder.query<DataRTKReturnList<IResponseStatus>, undefined>({
      query: () => `/get-all`,
    }),
    checkUserId: builder.query<DataRTKReturnList<IResponseStatus>, string>({
      query: (userId) => `/check/${userId}`,
    }),
    //PATCH
    activateOrDeactivateUser: builder.mutation<DataRTKReturnList<IResponseStatus>, IRequestActivateOrDeactivate>({
      query: (content) => ({
        url: `/users/activate-or-deactivate`,
        method: 'PATCH',
        body: content,
      }),
    }),
    changePasswordUser: builder.mutation<DataRTKReturnList<IResponseStatus>, IRequestChangePassword>({
      query: (content) => ({
        url: `/users/change-password`,
        method: 'PATCH',
        body: content,
      }),
    }),
    //DELETE
    deleteUser: builder.mutation<DataRTKReturnList<IResponseStatus>, string>({
      query: (username) => ({
        url: `/users/delete/${username}`,
        method: 'DELETE',
      }),
    }),
    //PUT
    updateDetailsUser: builder.mutation<DataRTKReturnList<IResponseStatus>, IRequestUpdateDetails>({
      query: (content) => ({
        url: `/users/update-details`,
        method: 'PUT',
        body: content,
      }),
    }),
  })
})

export default userApi
